import React, { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { styled } from '@mui/system';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MainCard from 'ui-component/cards/MainCard';
import Chip from '@material-ui/core/Chip';

import SearchIcon from '@mui/icons-material/Search';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
// project imports

import { gridSpacing } from 'store/constant';
import { getCityStateList } from 'api_handlers/discovery';

import AdvancedFilters from './AdvancedFilters';
import PredictionModal from './PredictionModal';
import AppliedFilters from './AppliedFilters';
import SearchAutocomplete from './SearchAutocomplete';
import SocialMediaButton from '../Extra/SocialMediaIcon';

import {
  fetchInfluencersAction,
  showPremiumFeatureDialog,
  setAutoCompleteValues,
} from 'actions/planner2';
import { updateFilters, resetFilters } from 'actions/planner-filters';
import { getFieldOfInterest } from 'api_handlers/influencer';
import { categories } from 'components/Discovery/components/ActionBar/components/FilterDrawer/components/AudienceInterestFilter/categories';

import mixpanel from 'mixpanel-browser';

// ==============================|| PLUGIN - AUTOCOMPLETE ||============================== //

const ResizableTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  transition: 'width 0.3s ease',
  '&:focus-within': {
    width: '100%',
    zIndex: 9999,
  },
}));

const Search = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.planner2.user);
  const predictionStatus = useSelector((state) => state.planner2.predictionStatus);
  const autoCompleteValues = useSelector((state) => state.planner2.autoCompleteValues);
  const plannerFilters = useSelector((state) => state.plannerFilters);

  const gender = useSelector((state) => state.plannerFilters.gender || []);
  const platform = useSelector((state) => state.plannerFilters.platform);
  const creatorType = useSelector((state) => state.plannerFilters.creatorType || []);
  const locations = useSelector((state) => state.plannerFilters.locations);
  const categories = useSelector((state) => state.plannerFilters.categories);

  const [locationOptions, setLocationOptions] = React.useState([]);

  // Advanced filter states
  const [openAdvancedFilters, setOpenAdvancedFilters] = React.useState(false);
  // Predictions Modal
  const [openPredictions, setOpenPredictions] = React.useState(false);
  const [categoryOptions, setCategoryOptions] = React.useState([]);

  // const [autoCompleteValues, setAutoCompleteValues] = React.useState([]);

  const setFilters = () => {
    const postsText = autoCompleteValues.find((option) => option.type === 'PostSearch');
    if (postsText) dispatch(updateFilters('posts', postsText.value, false));
    else dispatch(updateFilters('posts', null, false));

    const bioText = autoCompleteValues.find((option) => option.type === 'BioSearch');
    if (bioText) dispatch(updateFilters('bio', bioText.value, false));
    else dispatch(updateFilters('bio', null, false));

    const categories = autoCompleteValues
      .filter((option) => option.group === 'Categories')
      .map((option) => option.name)
      .join(',');
    if (categories && categories.length > 0) dispatch(updateFilters('category', categories, false));
    else dispatch(updateFilters('category', null, false));

    const influencerIds = autoCompleteValues
      .filter((option) => option.group === 'Influencers' && option.type === 'influencers-found')
      .map((option) => option.id);
    if (influencerIds.length > 0) dispatch(updateFilters('ids', influencerIds, false));
    else dispatch(updateFilters('ids', null, false));

    // const newInfluencers = autoCompleteValues
    //   .filter((option) => option.group === 'Influencers' && option.type === 'influencer-not-found')
    //   .map((option) => option.id);

    // dispatch(updateNewInfluencerSearch(newInfluencers));

    dispatch(fetchInfluencersAction(1));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    dispatch(fetchInfluencersAction(1));

    try {
      const resp = await getCityStateList(true);
      setLocationOptions(resp.data);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  }, []);

  useEffect(() => {
    setFilters();
  }, [autoCompleteValues]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getFieldOfInterest();
        const categories = data.map((option) => option.name);
        console.log('Categories: ', categories);
        setCategoryOptions(categories);
      } catch (error) {
        console.error('Error fetching field of interest:', error);
      }
    };
    fetchData();
  }, []);

  const memoizedValue = useMemo(() => (locations ? locations : []), [locations]);

  // useEffect(() => {
  //   console.log("Planner filters changed", plannerFilters);
  //   let values = [...autoCompleteValues]
  //   if(!plannerFilters.posts && values.find((option) => option.type === "PostSearch")) {
  //     values = values.filter((option) => option.type !== "PostSearch");
  //   }

  //   if(!plannerFilters.bio && values.find((option) => option.type === "BioSearch")) {
  //     values = values.filter((option) => option.type !== "BioSearch");
  //   }

  //   if(!plannerFilters.category && values.find((option) => option.group === "Categories")) {
  //     values = values.filter((option) => option.group !== "Categories");
  //   }

  //   if(!plannerFilters.ids && values.find((option) => option.group === "Influencers")) {
  //     values = values.filter((option) => option.group !== "Influencers");
  //   }
  //   // setAutoCompleteValues(values);
  // }, [plannerFilters]);

  return (
    <MainCard sx={{ background: '#fcf8ef' }}>
      <Grid container spacing={gridSpacing}>
        <Grid item width={'100%'}>
          <Grid container direction={'column'} justifyContent={'center'}>
            <Grid item>
              <Typography
                sx={{
                  fontSize: '45px',
                  fontWeight: 600,
                  color: '#f9b715',
                }}
              >
                Discover Influencers for your Brand
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '15px',
                  mt:'5px'
                }}
              >
                Discover from 2,00,000+ influencers across India on YouTube or Instagram
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} sx={{mt:'20px'}}>
          <Grid container spacing={1} alignItems={'center'} justifyContent={'space-between'}>
            <Grid
              item
              xs={11}
              // sx={{ border: '2px solid #000', borderRadius: 2, background: '#f8fafc' }}
            >
              <Grid container>
                <Grid item xs={2}>
                  <FormControl
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#000',
                        },
                      },
                      '& fieldset': {
                        borderRight: 'none',
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        height: autoCompleteValues.length > 0 ? 60 : 58,
                      },
                      '& MuiSelect-icon': {
                        top: 'calc(50% - 0.6em)',
                      },
                    }}
                  >
                    <InputLabel id="platform-select">Platform</InputLabel>
                    <Select
                      labelId="platform-select"
                      id="platform"
                      name="platform"
                      value={platform}
                      onChange={(event) => {
                        if (process.env.NODE_ENV === 'production') {
                          mixpanel.track('platform_changed', {
                            type: event.target.value,
                          });
                        }
                        dispatch(updateFilters('platform', event.target.value, true));
                      }}
                      label="Platform"
                    >
                      <MenuItem value={'instagram'}>
                        <Grid container spacing={1}>
                          <Grid item>
                            <SocialMediaButton platform="instagram" />
                          </Grid>
                          <Grid item>
                            <Typography
                              sx={{ fontWeight: 800, fontSize: '0.8rem', marginTop: '2px' }}
                            >
                              Instagram
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                      <MenuItem value={'youtube'}>
                        <Grid container spacing={1}>
                          <Grid item>
                            <SocialMediaButton platform="youtube" />
                          </Grid>
                          <Grid item>
                            <Typography
                              sx={{ fontWeight: 800, fontSize: '0.8rem', marginTop: '2px' }}
                            >
                              YouTube
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={10}>
                  <SearchAutocomplete />
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={'auto'}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<SearchIcon sx={{color: 'primary.main'}} />}
                onClick={setFilters}
                style={{
                  height: 40,
                }}
              >
                <Typography variant="h5" color="primary" fontWeight={600}>Search</Typography>
              </Button>
            </Grid> */}
            <Grid item xs={'auto'}>
              <Button
                variant="text"
                color="secondary"
                onClick={() => {
                  console.log('Resetting filters');
                  dispatch(resetFilters());
                  dispatch(setAutoCompleteValues([]));
                }}
              >
                <Typography variant="h5" color="secondary" fontWeight={600} sx={{ opacity: 0.5 }}>
                  Reset
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container spacing={1} alignItems={'center'} justifyContent={'left'}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={1} alignItems={'center'} justifyContent={'left'}>
                <Grid item xs={2}>
                  <Autocomplete
                    multiple
                    size="small"
                    value={memoizedValue}
                    options={locationOptions}
                    renderTags={(values) => (
                      <>
                        <span
                          style={{
                            backgroundColor: 'rgb(197, 210, 255)',
                            borderRadius: '4px',
                            color: 'rgb(18, 25, 38)',
                            fontSize: '0.5rem',
                            paddingLeft: '1.5%',
                            paddingRight: '1.5%',
                          }}
                        >
                          {values[0].length > 7 ? `${values[0].substring(0, 7)}...` : values[0]}
                        </span>
                        {values.length > 1 ? (
                          <span
                            style={{
                              backgroundColor: 'rgb(197, 210, 255)',
                              borderRadius: '4px',
                              color: 'rgb(18, 25, 38)',
                              fontSize: '0.5rem',
                              marginLeft: '2.5%',
                              paddingLeft: '1.5%',
                              paddingRight: '1.5%',
                            }}
                          >
                            {'+' + (values.length - 1)}
                          </span>
                        ) : null}
                      </>
                    )}
                    renderInput={(params) => <ResizableTextField label="Location" {...params} />}
                    onChange={(event, value) => {
                      if (value && value.length > 0)
                        dispatch(updateFilters('locations', value, true));
                      else dispatch(updateFilters('locations', null, true));
                    }}
                    limitTags={0}
                    fullWidth
                    sx={{
                      border: '1px solid #000',
                      borderRadius: 2,
                      height: '42px',
                      '& fieldset': { height: '51px', top: '-7px', border: 'none' },
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      border: '1px solid #000',
                      borderRadius: 2,
                      '& fieldset': { border: 'none' },
                    }}
                  >
                    <InputLabel id="gender-select">Gender</InputLabel>
                    <Select
                      multiple
                      labelId="gender-select"
                      id="gender"
                      name="gender"
                      value={gender}
                      onChange={(event) => {
                        // setGender(event.target.value);
                        dispatch(updateFilters('gender', event.target.value, true));
                        // if (event.target.value)
                        //   dispatch(updateFilters('gender', event.target.value, true));
                        // else dispatch(updateFilters('gender', null, true));
                      }}
                      label="Platform"
                    >
                      <MenuItem value={'male'}>Male</MenuItem>
                      <MenuItem value={'female'}>Female</MenuItem>
                      <MenuItem value={'other'}>Other</MenuItem>
                      <MenuItem value={'page'}>Page</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={2}>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      border: '1px solid #000',
                      borderRadius: 2,
                      '& fieldset': { border: 'none' },
                    }}
                  >
                    <InputLabel id="platform-select">Creator Type</InputLabel>
                    <Select
                      multiple
                      labelId="platform-select"
                      id="platform"
                      name="platform"
                      value={creatorType}
                      onChange={(event) => {
                        dispatch(updateFilters('creatorType', event.target.value, true));
                      }}
                      label="Platform"
                      fullWidth
                    >
                      <MenuItem value={'mega'}>{`Mega ( > 1M)`}</MenuItem>
                      <MenuItem value={'macro'}>{`Macro (100K - 1M)`}</MenuItem>
                      <MenuItem value={'micro'}>{`Micro (10K - 100K)`}</MenuItem>
                      <MenuItem value={'nano'}>{`Nano ( < 10K)`}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* <Grid item xs={2}>
                  <Autocomplete
                    multiple
                    size="small"
                    value={categories}
                    options={categoryOptions}
                    renderInput={(params) => <ResizableTextField label="Category" {...params} />}
                    onChange={(event, value) => {
                      if (value && value.length > 0)
                        dispatch(
                          updateFilters(
                            'categories',
                            value,
                            true,
                          ),
                        );
                      else dispatch(updateFilters('categories', null, true));
                    }}
                    limitTags={0}
                    fullWidth
                    sx={{'& fieldset': {height: '47px', top: '-7px'}}}
                  />
                </Grid> */}

                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    // startIcon={<WorkspacePremiumIcon sx={{color: 'primary.main'}}/>}
                    // onClick={() => {
                    //   if(user.premium_user) setOpenAdvancedFilters(true);
                    //   else dispatch(showPremiumFeatureDialog(true));

                    // }}
                    onClick={() => {
                      setOpenAdvancedFilters(true);
                    }}
                    style={{
                      height: 40,
                      borderRadius: 7,
                    }}
                  >
                    <Typography variant="h5" color="primary" fontWeight={600}>
                      Advanced Filters
                    </Typography>
                  </Button>
                </Grid>
                {predictionStatus === 'loaded' && (
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      startIcon={<WorkspacePremiumIcon sx={{ color: 'primary.main' }} />}
                      onClick={() => setOpenPredictions(true)}
                      style={{
                        height: 40,
                        borderRadius: 7,
                      }}
                    >
                      <Typography variant="h5" color="primary" fontWeight={600}>
                        Predictions
                      </Typography>
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <AppliedFilters />
      </Grid>
      <AdvancedFilters open={openAdvancedFilters} setOpen={setOpenAdvancedFilters} />
      <PredictionModal open={openPredictions} setOpen={setOpenPredictions} />
    </MainCard>
  );
};

export default Search;
