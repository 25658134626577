import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Chip, IconButton, Switch as SwitchRadio } from '@material-ui/core';

import BaseSidePanel from '../ux/BaseSidePanel';

import { campaignActions } from '../../actions/campaign';

import CampaignLogo from '../../assets/campaign-logo.svg';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import VideocamIcon from '@material-ui/icons/Videocam';
import PeopleIcon from '@material-ui/icons/People';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import ListIcon from '@material-ui/icons/List';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { withStyles } from '@material-ui/core/styles';
import { isWhatsAppModuleEnable } from 'components/utils';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#444',
        borderColor: '#444',
      },
    },
  },
  thumb: {
    width: 11,
    height: 11,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 14 / 2,
    opacity: 1,
    height: 14,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(SwitchRadio);

class CampaignSidePanel extends React.Component {
  constructor(props) {
    super(props);

    this.handleDrawerAction = this.handleDrawerAction.bind(this);

    this.state = {
      drawerOpen: false,
    };
  }

  componentDidMount() {
    this.props.getCampaignDetails(this.props.match.params.campaignId);
  }

  handleAlignment = (event, element) => {
    let alignment = element ? 'left' : 'right';
    this.props.changeInfluencersAlignment(alignment);
  };

  handleDrawerAction(drawerOpen) {
    this.setState({ drawerOpen: drawerOpen });
  }

  getNavigationLinks() {
    const links = [
      {
        to: `${this.props.match.url}/dashboard/${
          this.props.match.params.campaignId === '137' || (this.props.current_campaign.platform && this.props.current_campaign.platform.length > 0 && this.props.current_campaign.platform.includes('instagram')) ? 'instagram' : 'youtube'
        }`,
        label: 'Dashboard',
        icon: EqualizerIcon,
        children: [],
      },
      {
        to: `${this.props.match.url}/feeds/${
          this.props.match.params.campaignId === '137' || (this.props.current_campaign.platform && this.props.current_campaign.platform.length > 0 && this.props.current_campaign.platform.includes('instagram')) ? 'instagram' : 'youtube'
        }`,
        label: 'Feeds',
        icon: VideocamIcon,
        children: [],
      },
      {
        to: `${this.props.match.url}/influencers/eligible-influencers`,
        label: 'Influencers',
        icon: PeopleIcon,
        children: [
          {
            to: `${this.props.match.url}/influencers/eligible-influencers`,
            label: 'All Influencers',
            count: this.props.current_campaign.eligible_influencer,
            show_count: true,
            icon: ListIcon,
          },
          {
            to: `${this.props.match.url}/influencers/approved-influencers`,
            label: 'Approved Influencers',
            count: this.props.current_campaign.accepted_influencer,
            show_count: true,
            icon: CheckCircleOutlineIcon,
          },
          {
            to: `${this.props.match.url}/influencers/pending-approval`,
            label: 'Pending Approval',
            count: this.props.current_campaign.pending_influencer,
            show_count: true,
            icon: HourglassEmptyIcon,
          },
          {
            to: `${this.props.match.url}/influencers/rejected-influencers`,
            label: 'Rejected Influencers',
            count: this.props.current_campaign.rejected_influencer,
            show_count: true,
            icon: CancelIcon,
          },
        ],
      },
    ];

    if (isWhatsAppModuleEnable()) {
      links.push({
        to: `${this.props.match.url}/communication`,
        label: 'Communication',
        icon: PeopleIcon,
        children: [
        ],
      });
      links.push({
        to: `${this.props.match.url}/content-approval`,
        label: 'Content Approval',
        icon: ListIcon,
        children: [
        ],
      });
    }

    links.forEach((link) => {
      if (!this.props.allowedSocailMediaLinks || link.label === 'Influencers' || link.label == 'Communication' || link.label == 'Content Approval') return;

      if (this.props.allowedSocailMediaLinks.includes('youtube') && (this.props.current_campaign.platform && (this.props.current_campaign.platform.length === 0 || this.props.current_campaign.platform.includes('youtube')))) {
        const child = {
          label: 'Youtube',
          icon: YouTubeIcon,
        };

        if (link.label === 'Dashboard') child.to = `${this.props.match.url}/dashboard/youtube`;
        else if (link.label === 'Feeds') child.to = `${this.props.match.url}/feeds/youtube`;

        link.children.push(child);
      }

      if (this.props.allowedSocailMediaLinks.includes('instagram') && (this.props.current_campaign.platform && (this.props.current_campaign.platform.length === 0 || this.props.current_campaign.platform.includes('instagram')))) {
        const child = {
          label: 'Instagram',
          icon: InstagramIcon,
        };

        if (link.label === 'Dashboard') child.to = `${this.props.match.url}/dashboard/instagram`;
        else if (link.label === 'Feeds') child.to = `${this.props.match.url}/feeds/instagram`;

        link.children.push(child);
      }
    });

    console.log('==== links', links);

    return links;
  }

  render() {
    return (
      <Grid container>
        <BaseSidePanel
          renderHeader={(childProps) => {
            return (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ padding: 10 }}
              >
                <Grid item md={'auto'}>
                  <img
                    src={CampaignLogo}
                    alt="campaign-logo"
                    className="campaign-logo InlineBlock"
                    style={{
                      marginRight: '10px',
                    }}
                  />
                </Grid>
                {childProps.drawerOpen ? (
                  <Grid item md={8}>
                    <Grid
                      container
                      direction="column"
                      justify="flex-end"
                      alignItems="flex-start"
                      spacing={1}
                    >
                      <Grid item md={12}>
                        <span style={{ wordBreak: 'break-all', fontSize: '13px' }}>
                          {this.props.current_campaign.name}
                        </span>
                      </Grid>
                      <Grid item md={12}>
                        <Chip
                          label={
                            this.props.current_campaign.is_live
                              ? 'Live'
                              : this.props.current_campaign.status
                              ? this.props.current_campaign.status.replace(
                                  /\b[a-z]/g,
                                  function (letter) {
                                    return letter.toUpperCase();
                                  },
                                )
                              : ''
                          }
                          size="small"
                          style={{
                            background: this.props.current_campaign.is_live
                              ? '#8517C8'
                              : this.props.current_campaign.status &&
                                this.props.current_campaign.status == 'paused'
                              ? 'grey'
                              : this.props.current_campaign.status &&
                                this.props.current_campaign.status == 'completed'
                              ? '#65C817'
                              : 'grey',
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '11px',
                            padding: '0px 15px 0px',
                          }}
                        ></Chip>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            );
          }}
          navigationLinks={this.getNavigationLinks()}
          handleDrawerAction={this.handleDrawerAction}
        >
          {this.props.location &&
          this.props.location.pathname &&
          this.props.location.pathname.indexOf('influencers') > -1 ? (
            // <div style={{ position: 'fixed', bottom: '10px', left: '10px', zIndex: '9999',maxHeight:'100px' }}>
            <Grid container direction='column' justifyContent='flex-end' alignItems='flex-start'  style={{ position: 'relative',marginTop:'50px', bottom: '10px', left: '10px', zIndex: '9999', }}>
              <Grid item>

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"

                // style={{ margin: 10, marginRight: 20 }}
              >
                <Grid item md={12} style={{ margin: '2px 0px' }}>
                  <IconButton
                    variant="contained"
                    size="small"
                    style={{
                      fontSize: 12,
                      textTransform: 'none',
                      backgroundColor: '#444',
                      color: 'white',
                      margin: '1px',
                      padding: 3,
                    }}
                    onClick={() => {
                      this.props.downloadInfluencersReport();
                    }}
                  >
                    <CloudDownloadIcon />
                  </IconButton>
                  {this.state.drawerOpen ? (
                    <span style={{ fontSize: '13px', marginLeft: '3px' }}>Download CSV</span>
                  ) : null}
                </Grid>
              </Grid>

              </Grid>

              <Grid item>

              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <Grid item md={12} style={{ margin: '2px 2px' }}>
                  <div style={{ display: 'flex' }}>
                    <AntSwitch
                      checked={this.props.influencers_alignment == 'left'}
                      onChange={this.handleAlignment}
                      name="checkedC"
                    />
                    {this.state.drawerOpen ? (
                      <span style={{ fontSize: '13px', marginLeft: '6px' }}>Toggle View</span>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
                
              </Grid>

            </Grid>
             
              
            // </div>
          ) : null}
        </BaseSidePanel>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.campaign.loading,
  current_campaign: state.campaign.current_campaign,
  allowedSocailMediaLinks: state.brand.displayPanels?.social_media.link_display,
  influencers_alignment: state.campaign.influencers_alignment,
});

const actionCreators = {
  getCampaignDetails: campaignActions.getCampaignDetails,
  changeInfluencersAlignment: campaignActions.changeInfluencersAlignment,
  downloadInfluencersReport: campaignActions.downloadInfluencersReport,
};

export default connect(mapStateToProps, actionCreators)(withRouter(CampaignSidePanel));
