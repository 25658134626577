import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Grid} from '@mui/material';

import { gridSpacing } from 'store/constant';

import InfluencerList from '../InfluencerList';

import { getPlannerInfluencersES } from 'api_handlers/es';
import { fetchCuratedInfluencers } from 'api_handlers/planner2';
// import { useLocation } from 'react-router-dom';


const CuratedInfluencers = ({match: { params }}) => {
  const [influencers, setInfluencers] = useState([]);
  const [count, setCount] = useState(0)
  const [search,setSearch] = useState('')
  const { curatedItemId } = params;
  const {plannerItemId} = params
  const page = useSelector((state) => state.planner2.page) || 1;

  // const location = useLocation();
  // const message = location.state?.text || 'No message received';

  const fetchInfluencers = async (page) => {
    const response = await getPlannerInfluencersES(
      {
        "campaign-level-requirements": {},
        "creator-level-requirements": {},
        "advance-filters": {},
        "setReset": false
      }, page);
    const {results} = await response.json();
    setInfluencers(results);
  }

  const updateFilters = async () => {}

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    // fetchCuratedInfluencers(curatedItemId?curatedItemId:plannerItemId, page).then((response)=>{
      fetchCuratedInfluencers(curatedItemId, page).then((response)=>{
      console.log("RESPONSE: ", response)
      setCount(response.count)
      setInfluencers(response.results.map(profile=>{
        return profile
      }));
    });
    // fetchInfluencers(1)
  }
  , [page]);

    return (
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={12}>
          <InfluencerList
            influencers={influencers}
            fetchInfluencers={fetchInfluencers}
            totalResults={count}
            updateFilters={updateFilters}
            // text={message}
            successCount={(val) => ''}
            onSearch={val=>setSearch(val)}
          />
        </Grid>
      </Grid>
    )
}

export default CuratedInfluencers;