import { Typography, Grid, Table, TableHead, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';

import FollowerIcon from 'assets/discovery/followers-icon.png';
import InterestIcon from 'assets/discovery/interest-icon.png';
import BrandAffinityIcon from 'assets/discovery/Brand-affiniy-icon.png';
import PriceIcon from 'assets/discovery/Price-icon.png';
import FilterVintageIcon from '@material-ui/icons/FilterVintage';
import Location from 'assets/discovery/Location-Icon.png';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LikeIcon from 'assets/discovery/like-icon.png';
import TwitterIcon from 'assets/planner/twitter_icon.png';
import ActorIcon from 'assets/planner/Actor.png';
import AgricultureIcon from 'assets/planner/Agriculture.png';
import ArtCraftIcon from 'assets/planner/Art-Craft.png';
import AutomobileIcon from 'assets/planner/Automobile.png';
import BeautyIcon from 'assets/planner/beauty.png';
import BusinessCareerIcon from 'assets/planner/Business-Careers.png';
import CareerCoachIcon from 'assets/planner/Career-Coach.png';
import ComedyIcon from 'assets/planner/Comedy.png';
import CryptoIcon from 'assets/planner/Crypto.png';
import DanceIcon from 'assets/planner/Dance.png';
import DecorIcon from 'assets/planner/Decor.png';
import DigitalMarketingIcon from 'assets/planner/Digital-Marketing.png';
import EducationIcon from 'assets/planner/Education.png';
import EntertainmentPageIcon from 'assets/planner/Entertainment-Page.png';
import EntertainmentIcon from 'assets/planner/Entertainment.png';
import FanPageIcon from 'assets/planner/Fan-Page.png';
import FashionIcon from 'assets/planner/Fashion.png';
import FinanceIcon from 'assets/planner/Finance.png';
import FoodIcon from 'assets/planner/Food.png';
import GadgetReviewIcon from 'assets/planner/Gadget-Review.png';
import GamingIcon from 'assets/planner/Gaming.png';
import GardeningIcon from 'assets/planner/Gardening.png';
import GraphicDesignerIcon from 'assets/planner/Graphic-Designer.png';
import HaircareIcon from 'assets/planner/Haircare.png';
import HealthIcon from 'assets/planner/Health.png';
import InfortainmentIcon from 'assets/planner/Infotainment.png';
import InvestmentIcon from 'assets/planner/Investment.png';
import KidsIcon from 'assets/planner/Kids.png';
import LegalIcon from 'assets/planner/Legal.png';
import LifestyleIcon from 'assets/planner/Lifestyle.png';
import LiteratureIcon from 'assets/planner/Literature.png';
import MemePageIcon from 'assets/planner/Meme-Page.png';
import MensGroomingIcon from 'assets/planner/Mens-Grooming.png';
import ModelIcon from 'assets/planner/Model.png';
import MusicIcon from 'assets/planner/Music.png';
import NewsMediaIcon from 'assets/planner/News-Media.png';
import NutritionIcon from 'assets/planner/Nutrition.png';
import ParentingIcon from 'assets/planner/Parenting.png';
import PetsIcon from 'assets/planner/Pets.png';
import PhotographyIcon from 'assets/planner/Photography.png';
import ProductReviewIcon from 'assets/planner/Product-Review.png';
import SelfMotivationIcon from 'assets/planner/Self-Help-Motivation.png';
import SkincareIcon from 'assets/planner/Skincare.png';
import SpiritualityIcon from 'assets/planner/Spirituality.png';
import SportsIcon from 'assets/planner/Sports.png';
import StockMarketIcon from 'assets/planner/Stock-Market.png';
import Sustainability from 'assets/planner/Sustainability.png';
import TechnologyIcon from 'assets/planner/Technology.png';
import TheatreIcon from 'assets/planner/Theatre.png';
import TravelIcon from 'assets/planner/Travel.png';
import TvReviewIcon from 'assets/planner/TV-Review.png';
import WebsiteToolsIcon from 'assets/planner/Website-Tools.png';
import YogaIcon from 'assets/planner/Yoga.png';

import ActivewearIcon from 'assets/planner/Activewear.png';
import ArtDesignIcon from 'assets/planner/Art-and-Design.png';
import BeautyCosmeticIcon from 'assets/planner/Beauty-and-Cosmetics.png';
import BeerWineIcon from 'assets/planner/Beer-Wine-&-Spirits.png';
import CameraPhotographyIcon from 'assets/planner/Camera-&-Photography.png';
import CarMotorbikeIcon from 'assets/planner/Cars-&-Motorbikes.png';
import ClothesShoesIcon from 'assets/planner/Clothes-Shoes.png';
import CoffeeTeaIcon from 'assets/planner/Coffee-Tea-&-Beverages.png';
import ElectronicsComputerIcon from 'assets/planner/Electronics-and-Computers.png';
import FitnessYogaIcon from 'assets/planner/Yoga.png';
import FriendsFamilyIcon from 'assets/planner/Friends-Family-&-Relationships.png';
import HealthcareMedicineIcon from 'assets/planner/Healthcare-and-Medicine.png';
import HealthyLifestyleIcon from 'assets/planner/Healthy-Lifestyle.png';
import HomeDecorIcon from 'assets/planner/Home-Decor-Furniture-&-Garden.png';
import JewlleryWatchesIcon from 'assets/planner/Jewellery-&-Watches.png';
import LuxaryGoodsIcon from 'assets/planner/Luxury-Goods.png';
import RestaurantFoodIcon from 'assets/planner/Restaurants-Food-&-Grocery.png';
import ShoppingRetail from 'assets/planner/Shopping-&-Retail.png';
import TelevisionFilmsIcon from 'assets/planner/Television-and-Film.png';
import TobaccoSmokingIcon from 'assets/planner/Tobacco-&-Smoking.png';
import ToysIcon from 'assets/planner/Toys-Children-&-Baby.png';
import TravelTourismIcon from 'assets/planner/Travel-Tourism-and-Aviation.png';
import WeddingIcon from 'assets/planner/Wedding.png';
import { fontSize } from '@material-ui/system';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
);

const interestsIcon = {
  Actor: ActorIcon,
  Agriculture: AgricultureIcon,
  'Art and craft': ArtCraftIcon,
  Automobile: AutomobileIcon,
  Beauty: BeautyIcon,
  'Career Coach': CareerCoachIcon,
  Comedy: ComedyIcon,
  Crypto: CryptoIcon,
  Dance: DanceIcon,
  Decor: DecorIcon,
  'Digtal Marketing': DigitalMarketingIcon,
  Education: EducationIcon,
  'Entertainment Page': EntertainmentPageIcon,
  Entertainment: EntertainmentIcon,
  'Fan Page': FanPageIcon,
  Fashion: FashionIcon,
  'Fashion	Male': MensGroomingIcon,
  Finance: FinanceIcon,
  Fitness: HealthIcon,
  Food: FoodIcon,
  'Gadget Review': GadgetReviewIcon,
  Gaming: GamingIcon,
  'Gaming / E-sports': GamingIcon,
  'Graphic Designer': GraphicDesignerIcon,
  Haircare: HaircareIcon,
  Health: HealthIcon,
  'home decor ': DecorIcon,
  Infotainment: InfortainmentIcon,
  Investment: InvestmentIcon,
  Kids: InvestmentIcon,
  Legal: LegalIcon,
  Lifestyle: LifestyleIcon,
  'Meme Page': MemePageIcon,
  "Men's Grooming": MensGroomingIcon,
  Model: ModelIcon,
  'News Media': NewsMediaIcon,
  Nutrition: NutritionIcon,
  Motivation: SelfMotivationIcon,
  'Self Motivation': SelfMotivationIcon,
  Music: MusicIcon,
  Parenting: ParentingIcon,
  Pets: PetsIcon,
  Photography: PhotographyIcon,
  'Product Review': ProductReviewIcon,
  Skincare: SkincareIcon,
  Spirituality: SpiritualityIcon,
  'Stock Market': StockMarketIcon,
  Sustainability: Sustainability,
  Theatre: TheatreIcon,
  'Self Help': SelfMotivationIcon,
  Sports: SportsIcon,
  Tech: TechnologyIcon,
  Technology: TechnologyIcon,
  Literature: LiteratureIcon,
  Travel: TravelIcon,
  'Tv Review': TvReviewIcon,
  'Website Tools': WebsiteToolsIcon,
  'Travel / Content Creator': TravelIcon,
  'Travel/ Food': TravelIcon,
  'Travel/ lifestyle': TravelIcon,
  'Television & Film': TvReviewIcon,
  'Business & Careers': BusinessCareerIcon,
  'Healthcare & Medicine': HealthIcon,
  'Toys, Children & Baby': KidsIcon,
  'Fitness & Yoga': YogaIcon,
  'Healthy Lifestyle': HealthIcon,
  'Luxury Goods': LifestyleIcon,
  'Home Decor, Furniture & Garden': GardeningIcon,
  'Friends, Family & Relationships': ParentingIcon,
  'Television & Film': TelevisionFilmsIcon,
  Music: MusicIcon,
  'Shopping & Retail': ShoppingRetail,
  'Coffee, Tea & Beverages': CoffeeTeaIcon,
  'Camera & Photography': CameraPhotographyIcon,
  'Clothes, Shoes, Handbags & Accessories': ClothesShoesIcon,
  'Beer, Wine & Spirits': BeerWineIcon,
  Sports: SportsIcon,
  'Electronics & Computers': ElectronicsComputerIcon,
  Gaming: GamingIcon,
  Activewear: ActivewearIcon,
  'Art & Design': ArtDesignIcon,
  'Travel, Tourism & Aviation': TravelTourismIcon,
  'Business & Careers': BusinessCareerIcon,
  'Beauty & Cosmetics': BeautyCosmeticIcon,
  'Healthcare & Medicine': HealthcareMedicineIcon,
  'Jewellery & Watches': JewlleryWatchesIcon,
  'Restaurants, Food & Grocery': RestaurantFoodIcon,
  'Toys, Children & Baby': ToysIcon,
  'Fitness & Yoga': FitnessYogaIcon,
  Wedding: WeddingIcon,
  'Tobacco & Smoking': TobaccoSmokingIcon,
  Pets: PetsIcon,
  'Healthy Lifestyle': HealthyLifestyleIcon,
  'Luxury Goods': LuxaryGoodsIcon,
  'Home Decor, Furniture & Garden': HomeDecorIcon,
  'Friends, Family & Relationships': FriendsFamilyIcon,
  'Cars & Motorbikes': CarMotorbikeIcon,
};

const dummyData = {
  username: 'hiphopunofficial',
  fullname: 'Hiphop unofficial',
  url: 'https://www.instagram.com/hiphopunofficial',
  // 'picture': 'https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDZamZ5Z7xQIUNO094fHaxAdR5uAvdtZ2%2F7CzuicVRY3UnlP2wPf%2BO7j8qGwy4CXajMihLxWsHhCZj3tNJcx7fLrgNlJUwVYqIiahjW32e%2BqWA%3D%3D',
  picture: 'https://influencer-media.s3.ap-south-1.amazonaws.com/232192182',
  followers: 5609,
  engagements: 3990,
  is_verified: false,
  location: 'Mumbai',
};

const MONTH = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

function InfluencerDetails({
  interests = [],
  fair_price,
  brand_affinity = [],
  avg_comments,
  avg_likes,
  stat_history,
  interest_categories = [],
  similar_audience,
  similar_users,
}) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item md={12} className={classes.header}>
        <Typography variant="h3" style={{ color: 'black',fontSize:16 }}>
          INFLUENCER DETAILS
        </Typography>
      </Grid>
      <Grid item md={12} style={{marginTop:10}}>
        <Grid container justifyContent="center">
          <Grid item md={4} className={classNames(classes.card, classes.graphCard)}>
            <Grid container spacing={2}>
              <Grid item>
                <img src={FollowerIcon} alt="icon" className={classes.iconSize} />
              </Grid>
              <Grid item>
                <Typography variant="h3" style={{ color: '#D94343' }}>
                  Followers
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" spacing={1}>
              <Grid item style={{ height: 300, width: 400, marginLeft: 5 }}>
                <Line
                  data={{
                    labels: stat_history?.map((x) => MONTH[x['month'].split('-')[1]]),
                    datasets: [
                      {
                        label: 'Followers',
                        data: stat_history?.map((x) => x['followers']),
                        borderColor: 'rgb(239, 26, 116)',
                        backgroundColor: 'rgba(239, 26, 116, 0.5)',
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} className={classNames(classes.card, classes.graphCard)}>
            <Grid container spacing={1}>
              <Grid item>
                <img src={LikeIcon} alt="icon" className={classes.iconSize} />
              </Grid>
              <Grid item>
                <Typography variant="h3" style={{ color: '#D94343' }}>
                  Likes
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item style={{ height: 300, width: 400, marginLeft: 5 }}>
                <Line
                  data={{
                    labels: stat_history?.map((x) => MONTH[x['month'].split('-')[1]]),
                    datasets: [
                      {
                        label: 'Likes',
                        data: stat_history?.map((x) => x['avg_likes']),
                        borderColor: 'rgb(239, 26, 116)',
                        backgroundColor: 'rgba(239, 26, 116, 0.5)',
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={4}
            className={classNames(classes.card, classes.graphCard)}
            style={{ minWidth: '30%' }}
          >
            <Grid container spacing={1} style={{ marginBottom: 8 }}>
              <Grid item>
                <img src={InterestIcon} alt="icon" className={classes.iconSize} />
              </Grid>
              <Grid item>
                <Typography variant="h3" style={{ color: '#0061A7' }}>
                  Interests
                </Typography>
              </Grid>
            </Grid>
            {interest_categories !== '' ? (
              <Grid container spacing={1} style={{ overflowY: 'scroll', maxHeight: '80%' }}>
                {interest_categories
                  ? interest_categories.slice(0, 3).map((interest, i) => {
                      return (
                        <Grid item md={12} className={classes.interest} key={i}>
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item md={2} className={classes.interestIconContainer}>
                              {/* <FilterVintageIcon className={classes.interestIcon}/> */}
                              <img
                                src={
                                  interestsIcon[Object.keys(interest).length > 0 ? interest : '']
                                }
                                style={{
                                  color: '#B9B9B9',
                                  fontSize: 13,
                                  width: 18,
                                  height: 18,
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={9}
                              style={{
                                marginLeft: 10,
                                textAlign: 'start',
                              }}
                            >
                              <Typography variant="subtitle1" style={{ fontSize: '0.8rem' }}>
                                {Object.keys(interest).length > 0 ? interest : ''}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })
                  : ''}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12} className={classNames(classes.card, classes.brandAffinityCard)}>
        <Grid container spacing={2}>
          <Grid item>
            <img src={BrandAffinityIcon} alt="icon" className={classes.iconSize} />
          </Grid>
          <Grid item>
            <Typography variant="h3" style={{ color: 'black' }}>
              Influencer Brand Affinity
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 20, marginLeft: 20 }}>
          {(Array.isArray(brand_affinity) ? brand_affinity.slice(0, 5) : []).map((brand, i) => (
            <Grid item className={classes.brandContainer} key={i}>
              <Typography>{brand.name}</Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {/* <Grid item md={12} className={classNames(classes.card, classes.pricingCard)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <img src={PriceIcon} alt="icon" className={classes.iconSize} />
          </Grid>
          <Grid item>
            <Typography variant="h3" style={{ color: '#FD6A00' }}>
              Pricing
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 20, marginLeft: 5 }} spacing={3}>
          <Grid item md={2} className={classes.fairPriceValueBox}>
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h3" style={{ color: 'black' }}>
                  {`Fair Price(Beta)`}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3" style={{ color: '#FD6A00' }}>
                  {fair_price}
                </Typography>
              </Grid>
            </Grid>
          </Grid> */}
          {
            //   <Grid item md={3}>
            //   <Grid container>
            //     <Grid item md={12}>
            //       <Typography variant="h3" style={{ color: 'black' }}>
            //         Details
            //       </Typography>
            //     </Grid>
            //     <Grid item md={12}>
            //       {/* <Typography variant="body1"> */}
            //       {/*     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut */}
            //       {/* </Typography> */}
            //     </Grid>
            //   </Grid>
            // </Grid>
          }
        {/* </Grid>
      </Grid> */}
      <Grid item md={12}>
        <Grid container>
          <Grid item md={6}>
            <Grid item md={12} style={{marginLeft:-0}} className={classNames(classes.card, classes.similarCard)}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  {/* <img src={PriceIcon} alt="icon" className={classes.iconSize} /> */}
                </Grid>
                <Grid item>
                  <Typography variant="h3" style={{ color: '#FD6A00' }}>
                    Similar Audience
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12} style={{ overflow: 'scroll', height: 430, marginTop: 20 }}>
                  {similar_audience && similar_audience.length > 0 ? (
                    <Table style={{ width: '100%' }}>
                      <TableHead>
                        <TableCell style={{ width: '60%' }} className={classes.tableHeader}>
                          Influencer
                        </TableCell>
                        <TableCell className={classes.tableHeader}>Engagements</TableCell>
                        <TableCell className={classes.tableHeader}>Followers</TableCell>
                      </TableHead>

                      {similar_audience.map((data) => {
                        return (
                          <TableBody>
                            <TableCell>
                              <Grid container alignItems="center">
                                <Grid item>
                                  <img
                                    src={data.picture}
                                    style={{ width: 30, borderRadius: 50 }}
                                  ></img>
                                </Grid>
                                <Grid item style={{ marginLeft: 15 }}>
                                  <Grid container direction="column">
                                    <Grid item>
                                      <Grid container alignItems="flex-end">
                                        <Grid item>
                                          {data.username.length > 0 ? (
                                            <Typography
                                              className={classes.tableData}
                                              style={{ cursor: 'pointer', color: '#0022aa' }}
                                              onClick={() => {
                                                window.open(data.url, '_blank');
                                              }}
                                            >
                                              {data.username}
                                            </Typography>
                                          ) : (
                                            <Typography
                                              className={classes.tableData}
                                              style={{ cursor: 'pointer', color: '#0022aa' }}
                                              onClick={() => {
                                                window.open(data.url, '_blank');
                                              }}
                                            >
                                              {data.fullname}
                                            </Typography>
                                          )}
                                        </Grid>
                                        <Grid item>
                                          {data.is_verified ? (
                                            <img
                                              style={{ width: 15, marginLeft: 10 }}
                                              src={TwitterIcon}
                                            ></img>
                                          ) : (
                                            ''
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item>
                                      {data.location ? (
                                        <Grid container spacing={2} alignItems="center">
                                          <Grid item>
                                            <LocationOnIcon
                                              style={{ color: '#aa1111', width: 18 }}
                                            ></LocationOnIcon>
                                          </Grid>
                                          <Grid item>
                                            <Typography className={classes.tableData}>
                                              {data.location.length < 15
                                                ? data.location
                                                : `${data.location.slice(0, 15)}...`}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ) : (
                                        ''
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell align="center" className={classes.tableData}>
                              {data.engagements.toLocaleString()}
                            </TableCell>
                            <TableCell align="center" className={classes.tableData}>
                              {data.followers.toLocaleString()}
                            </TableCell>
                          </TableBody>
                        );
                      })}
                    </Table>
                  ) : (
                    <Grid
                      container
                      style={{ height: '100%' }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography>No data available</Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid item md={12} className={classNames(classes.card, classes.similarCard)}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  {/* <img src={PriceIcon} alt="icon" className={classes.iconSize} /> */}
                </Grid>
                <Grid item>
                  <Typography variant="h3" style={{ color: '#FD6A00' }}>
                    Similar Topics
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12} style={{ overflow: 'scroll', height: 430, marginTop: 20 }}>
                  {similar_users && similar_users.length > 0 ? (
                    <Table style={{ width: '100%' }}>
                      <TableHead>
                        <TableCell style={{ width: '60%' }} className={classes.tableHeader}>
                          Influencer
                        </TableCell>
                        <TableCell className={classes.tableHeader}>Engagements</TableCell>
                        <TableCell className={classes.tableHeader}>Followers</TableCell>
                      </TableHead>
                      {similar_users.map((data) => {
                        return (
                          <TableBody>
                            <TableCell>
                              <Grid container alignItems="center">
                                <Grid item>
                                  <img
                                    src={data.picture}
                                    style={{ width: 30, borderRadius: 50 }}
                                  ></img>
                                </Grid>
                                <Grid item style={{ marginLeft: 15 }}>
                                  <Grid container direction="column">
                                    <Grid item>
                                      <Grid container alignItems="flex-end">
                                        <Grid item>
                                          {data.username.length > 0 ? (
                                            <Typography
                                              className={classes.tableData}
                                              style={{ cursor: 'pointer', color: '#0022aa' }}
                                              onClick={() => {
                                                window.open(data.url, '_blank');
                                              }}
                                            >
                                              {data.username}
                                            </Typography>
                                          ) : (
                                            <Typography
                                              className={classes.tableData}
                                              style={{ cursor: 'pointer', color: '#0022aa' }}
                                              onClick={() => {
                                                window.open(data.url, '_blank');
                                              }}
                                            >
                                              {data.fullname}
                                            </Typography>
                                          )}
                                        </Grid>
                                        <Grid item>
                                          {data.is_verified ? (
                                            <img
                                              style={{ width: 15, marginLeft: 10 }}
                                              src={TwitterIcon}
                                            ></img>
                                          ) : (
                                            ''
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item>
                                      <Grid item>
                                        {data.location ? (
                                          <Grid container spacing={2} alignItems="center">
                                            <Grid item>
                                              <LocationOnIcon
                                                style={{ color: '#aa1111', width: 18 }}
                                              ></LocationOnIcon>
                                            </Grid>
                                            <Grid item>
                                              <Typography className={classes.tableData}>
                                                {data.location}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        ) : (
                                          ''
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell align="center" className={classes.tableData}>
                              {data.engagements.toLocaleString()}
                            </TableCell>
                            <TableCell align="center" className={classes.tableData}>
                              {data.followers.toLocaleString()}
                            </TableCell>
                          </TableBody>
                        );
                      })}
                    </Table>
                  ) : (
                    <Grid
                      container
                      style={{ height: '100%' }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography>No data available</Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  header: {
    background: '#f4f4fe',
    // boxShadow: '0px 4px 20px rgba(170, 170, 170, 0.25)',
    padding: 15,
    marginTop:10,
  },
  card: {
    // background: 'linear-gradient(135.2deg, white 10.88%, rgba(251, 252, 255, 0.76) 100%)',
    // boxShadow:'-5px 5px 10px rgba(215, 216, 220, 0.2), 5px -5px 10px rgba(215, 216, 220, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    padding: 20,
    border:'1px solid #dedede',
    marginLeft:-1,
    marginBottom:-1
  },
  graphCard: {
    height: 260,
  },
  iconSize: {
    width: 15,
    height: 15,
  },
  brandAffinityCard: {
    height: 130,
  },
  pricingCard: {
    height: 180,
  },
  interestIconContainer: {
    // background: "linear-gradient(135deg, white 0%, #FFFFFF 100%)",
    // boxShadow:
    //   "-5px 5px 10px rgba(220, 221, 222, 0.2), 5px -5px 10px rgba(220, 221, 222, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(220, 221, 222, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(220, 221, 222, 0.5)",
    borderRadius: '50%',
    padding: 3,
    width: 23,
    height: 23,
  },
  interestIcon: {
    color: '#B9B9B9',
    fontSize: 13,
  },
  fairPriceValueBox: {
    background: 'white',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 224, 0.2), 5px -5px 10px rgba(215, 216, 224, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 224, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 224, 0.5)',
    // borderRadius: 10,
    height: 100,
    padding: 20,
  },
  brandContainer: {
    background: 'white',
    boxShadow:
      '-5px 5px 10px rgba(218, 219, 222, 0.2), 5px -5px 10px rgba(218, 219, 222, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(218, 219, 222, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(218, 219, 222, 0.5)',
    // borderRadius: 10,
    padding: 12,
    marginLeft: 10,
  },
  tableHeader: {
    color: '#777777',
    fontSize: 16,
  },
  tableData: {
    fontSize: 16,
  },
  similarCard: {
    height: 500,
    // marginTop: 15,
  },
}));

export default InfluencerDetails;
