import { useEffect, useRef, useState } from 'react';
import { Grid, Typography, makeStyles, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import { Bar } from 'react-chartjs-2';

import LikeIcon from '../../assets/discovery/like-icon.png';
import CommentIcon from '../../assets/discovery/comment-icon.png';
import ViewIcon from '../../assets/View.png';
import insta_placeholder from '../../assets/discovery/insta_placeholder.png';
import InstagramIcon from 'assets/discovery/insta-icon.png';
import YoutubeIcon from 'assets/discovery/youtube-icon.png';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';

import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import EngagementRateIcon from 'assets/EngagementRate.png';

import { useParams } from 'react-router-dom';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { creatorsFetch, fetchInstaPostsOverTime, fetchYTPostsOverTime, getBase64HandlerData, getBase64YtHandlerData, getHandlerData, getListCompetitorBrands, getYtHandlerData, topCreatorFetch, ytCreatorsFetch, getCombinedBrandListCompetitor, campaignTypesYoutubeWithNormalTypeFetch, creatorTypesWithNormalTypeFetch, creatorTypesYoutubeWithNormalTypeFetch, updatePostData, updateProfileData } from 'api_handlers/competitor';

import ImageValidator from 'components/ux/ImageValidator';
import html2canvas from 'html2canvas'
import jspdf from 'jspdf'
import jsPDF from 'jspdf';

import { Line } from 'react-chartjs-2';

import moment from 'moment';

// var domtoimage = require('dom-to-image-more');

const colourPalette = {
    0 : 'rgb(255, 0, 0)',
    1 : 'rgb(60, 179, 113)',
    2 : 'rgb(255, 165, 0)',
    3 : 'rgb(106, 90, 205)',
    4 : 'rgb(60, 60, 60)',
} 

function countertostring(counter) {
    counter = parseFloat(counter);
    if (counter >= 1000 && counter < 999999) {
      return (
        parseFloat(counter / 1000.0)
          .toFixed(2)
          .toString() + 'K'
      );
    } else if (counter > 999999) {
      return (
        parseFloat(counter / 1000000.0)
          .toFixed(2)
          .toString() + 'M'
      );
    } else {
      return counter.toString();
    }
}


function calculate_price_index(follower_count=0, like_count=1, comment_count=1, post_count=1) {
    follower_count = follower_count || 0

    let estimated_engagement = (like_count + comment_count) / post_count
    let estimated_engagement_rate = (estimated_engagement * 100) / (follower_count || 1)
    let cost_min = 0
    let cost_max = 0

    if (parseInt(follower_count) < 10000) {
        cost_min = 2.5 * parseInt(estimated_engagement)
        cost_max = 4 * parseInt(estimated_engagement)
    } else if (parseInt(follower_count) >= 10000 && parseInt(follower_count) < 100000) {
        if (estimated_engagement_rate < 4) {
            cost_min = 12 * parseInt(estimated_engagement)
            cost_max = 14 * parseInt(estimated_engagement)
        } else {
            cost_min = 5 * parseInt(estimated_engagement)
            cost_max = 9 * parseInt(estimated_engagement)
        }
    } else if (parseInt(follower_count) >= 100000 && parseInt(follower_count) < 1000000) {
        if (estimated_engagement_rate < 4) {
            cost_min = 10 * parseInt(estimated_engagement)
            cost_max = 12 * parseInt(estimated_engagement)
        } else {
            cost_min = 3.5 * parseInt(estimated_engagement)
            cost_max = 6 * parseInt(estimated_engagement)
        }
    } else {
        if (estimated_engagement_rate < 4) {
            cost_min = 10 * parseInt(estimated_engagement)
            cost_max = 12 * parseInt(estimated_engagement)
        }
        else {
            cost_min = 1.5 * parseInt(estimated_engagement)
            cost_max = 3 * parseInt(estimated_engagement)
        }
    }

    if (cost_min && cost_min < 1000) {
        cost_min = cost_min + 1000
        cost_max = cost_max + 1000
    }

    return ((cost_max * 2) + (cost_min * 2)) / 2;
}

const calculateEngagementRate = (likes, comments, shares, views) => {
    likes = likes !== null ? likes : 0;
    comments = comments !== null ? comments : 0;
    shares = shares !== null ? shares : 0;
    if (views === 0 || views === null) {
      return 0;
    }
    const engagementRate = ((likes + comments + shares) / views) * 100;
    return parseFloat(engagementRate.toFixed(2));
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#00218d' : '#308fe8',
    },
  }));

function CompetitorReportAnalysisUI({ classes }) {
    const { brand_id } = useParams();
    const pdfRef = useRef()

    const [ competitor_brands, set_competitor_brands ] = useState([]);
    const [ summary, setSummary ] = useState({'instagram': 0, 'youtube': 0});
    const [ totalViews, setTotalViews ] = useState(0);
    const [ totalEng, setTotalEng ] = useState(0);
    const [ maxBugetObj, setMaxBugetObj ] = useState({});

    useEffect(() => {
        loadData();
    }, [brand_id])

    const loadData = async () => {
        const { results } = await getCombinedBrandListCompetitor(brand_id);
        set_competitor_brands(results.slice(0, 5));

        loadSummaryData(results.slice(0, 5), 0, {'instagram': 0, 'youtube': 0}, 0, 0);

    }
    
    const query = new URLSearchParams(window.location.href);
    const start_date = query.get('start_date')
    const end_date = query.get('end_date')

    const loadSummaryData = async (results, index, summary_dict, totalViews, totalEng) => {
        if (index < results.length) {
            let brandName = results[index].title;
            const resp = await creatorTypesWithNormalTypeFetch(results[index].ig_handle, start_date, end_date, '', '', results[index].excluded_handles);
            if (!summary_dict[brandName]) {
                summary_dict[brandName] = {};
            }
            if (resp.all_creators) {
                summary_dict[brandName].creators = resp.all_creators.creators || 0;
                summary_dict[brandName].followers = resp.all_creators.followers || 0;
                summary_dict[brandName].likes_count = resp.all_creators.likes_count || 0;
                summary_dict[brandName].comments_count = resp.all_creators.comments_count || 0;
                summary_dict[brandName].count = resp.all_creators.count || 0;
                summary_dict[brandName].engagement__rate = (
                    calculateEngagementRate(
                        resp.all_creators.likes_count || 0,
                        resp.all_creators.comments_count || 0,
                        0,
                        resp.all_creators.view_count || 0,
                    )
                );
                summary_dict[brandName].view_count = resp.all_creators.view_count || 0;
                summary_dict[brandName].fair_price = resp.all_creators.fair_price || 0;
                summary_dict[brandName]['instagram'] = {}
                summary_dict[brandName]['instagram'].creators = resp.all_creators.creators || 0;
                summary_dict[brandName]['instagram'].followers = resp.all_creators.followers || 0;
                summary_dict[brandName]['instagram'].likes_count = resp.all_creators.likes_count || 0;
                summary_dict[brandName]['instagram'].comments_count = resp.all_creators.comments_count || 0;
                summary_dict[brandName]['instagram'].count = resp.all_creators.count || 0;
                summary_dict[brandName]['instagram'].engagement__rate = resp.all_creators.engagement__rate || 0;
                summary_dict[brandName]['instagram'].view_count = resp.all_creators.view_count || 0;
                summary_dict[brandName]['instagram'].fair_price = resp.all_creators.fair_price || 0;
                summary_dict['instagram'] += resp.all_creators.creators || 0;
                totalViews += resp.all_creators.view_count || 0;
                totalEng += resp.all_creators.engagement__rate || 0;
            } 
            resp.creator_type.map((each) => {
                if (each.creator_type == 'macro') {
                    if (!summary_dict[brandName]['macro']) {
                        summary_dict[brandName]['macro'] = each;
                    } else {
                        summary_dict[brandName]['macro'].creators += each.creators || 0;
                        summary_dict[brandName]['macro'].followers += each.followers || 0;
                        summary_dict[brandName]['macro'].likes_count += each.likes_count || 0;
                        summary_dict[brandName]['macro'].comments_count += each.comments_count || 0;
                        summary_dict[brandName]['macro'].count += each.count || 0;
                        summary_dict[brandName]['macro'].engagement__rate += each.engagement__rate || 0;
                        summary_dict[brandName]['macro'].view_count += each.view_count || 0;
                        summary_dict[brandName]['macro'].fair_price += each.fair_price || 0;
                    }
                } else if (each.creator_type == 'nano') {
                    if (!summary_dict[brandName]['nano']) {
                        summary_dict[brandName]['nano'] = each;
                    } else {
                        summary_dict[brandName]['nano'].creators += each.creators || 0;
                        summary_dict[brandName]['nano'].followers += each.followers || 0;
                        summary_dict[brandName]['nano'].likes_count += each.likes_count || 0;
                        summary_dict[brandName]['nano'].comments_count += each.comments_count || 0;
                        summary_dict[brandName]['nano'].count += each.count || 0;
                        summary_dict[brandName]['nano'].engagement__rate += each.engagement__rate || 0;
                        summary_dict[brandName]['nano'].view_count += each.view_count || 0;
                        summary_dict[brandName]['nano'].fair_price += each.fair_price || 0;
                    }
                } else if (each.creator_type == 'micro') {
                    if (!summary_dict[brandName]['micro']) {
                        summary_dict[brandName]['micro'] = each;
                    } else {
                        summary_dict[brandName]['micro'].creators += each.creators || 0;
                        summary_dict[brandName]['micro'].followers += each.followers || 0;
                        summary_dict[brandName]['micro'].likes_count += each.likes_count || 0;
                        summary_dict[brandName]['micro'].comments_count += each.comments_count || 0;
                        summary_dict[brandName]['micro'].count += each.count || 0;
                        summary_dict[brandName]['micro'].engagement__rate += each.engagement__rate || 0;
                        summary_dict[brandName]['micro'].view_count += each.view_count || 0;
                        summary_dict[brandName]['micro'].fair_price += each.fair_price || 0;
                    }
                } else if (each.creator_type == 'mega') {
                    if (!summary_dict[brandName]['mega']) {
                        summary_dict[brandName]['mega'] = each;
                    } else {
                        summary_dict[brandName]['mega'].creators += each.creators || 0;
                        summary_dict[brandName]['mega'].followers += each.followers || 0;
                        summary_dict[brandName]['mega'].likes_count += each.likes_count || 0;
                        summary_dict[brandName]['mega'].comments_count += each.comments_count || 0;
                        summary_dict[brandName]['mega'].count += each.count || 0;
                        summary_dict[brandName]['mega'].engagement__rate += each.engagement__rate || 0;
                        summary_dict[brandName]['mega'].view_count += each.view_count || 0;
                        summary_dict[brandName]['mega'].fair_price += each.fair_price || 0;
                    }
                } else if (each.creator_type == 'normal') {
                    if (!summary_dict[brandName]['normal']) {
                        summary_dict[brandName]['normal'] = each;
                    } else {
                        summary_dict[brandName]['normal'].creators += each.creators || 0;
                        summary_dict[brandName]['normal'].followers += each.followers || 0;
                        summary_dict[brandName]['normal'].likes_count += each.likes_count || 0;
                        summary_dict[brandName]['normal'].comments_count += each.comments_count || 0;
                        summary_dict[brandName]['normal'].count += each.count || 0;
                        summary_dict[brandName]['normal'].engagement__rate += each.engagement__rate || 0;
                        summary_dict[brandName]['normal'].view_count += each.view_count || 0;
                        summary_dict[brandName]['normal'].fair_price += each.fair_price || 0;
                    }
                }
            })
            const resp_yt = await creatorTypesYoutubeWithNormalTypeFetch(results[index].yt_handle, start_date, end_date, '', '', results[index].excluded_channel_ids);
            if (resp_yt.all_creators) {
                summary_dict[brandName].creators += resp_yt.all_creators.creators || 0;
                summary_dict[brandName].followers += resp_yt.all_creators.followers || 0;
                summary_dict[brandName].likes_count += resp_yt.all_creators.likes_count || 0;
                summary_dict[brandName].comments_count += resp_yt.all_creators.comments_count || 0;
                summary_dict[brandName].count += resp_yt.all_creators.count || 0;
                summary_dict[brandName].engagement__rate = parseFloat((
                    summary_dict[brandName].engagement__rate + ((
                        calculateEngagementRate(
                            resp_yt.all_creators.likes_count || 0,
                            resp_yt.all_creators.comments_count || 0,
                            0,
                            resp_yt.all_creators.view_count || 0,
                        )
                    ))) / 2).toFixed(2);
                
                summary_dict[brandName].view_count += resp_yt.all_creators.view_count || 0;
                summary_dict[brandName].fair_price += resp_yt.all_creators.fair_price || 0;
                summary_dict[brandName]['youtube'] = {}
                summary_dict[brandName]['youtube'].creators = resp_yt.all_creators.creators || 0;
                summary_dict[brandName]['youtube'].followers = resp_yt.all_creators.followers || 0;
                summary_dict[brandName]['youtube'].likes_count = resp_yt.all_creators.likes_count || 0;
                summary_dict[brandName]['youtube'].comments_count = resp_yt.all_creators.comments_count || 0;
                summary_dict[brandName]['youtube'].count = resp_yt.all_creators.count || 0;
                summary_dict[brandName]['youtube'].engagement__rate = resp_yt.all_creators.engagement__rate || 0;
                summary_dict[brandName]['youtube'].view_count = resp_yt.all_creators.view_count || 0;
                summary_dict[brandName]['youtube'].fair_price = resp_yt.all_creators.fair_price || 0;
                summary_dict['youtube'] += resp_yt.all_creators.creators || 0;
                totalViews += resp_yt.all_creators.view_count || 0;
                totalEng += resp_yt.all_creators.engagement__rate || 0;
            }
            resp_yt.creator_type.map((each) => {
                if (each.creator_type == 'macro') {
                    if (!summary_dict[brandName]['macro']) {
                        summary_dict[brandName]['macro'] = each;
                    } else {
                        summary_dict[brandName]['macro'].creators += each.creators || 0;
                        summary_dict[brandName]['macro'].followers += each.followers || 0;
                        summary_dict[brandName]['macro'].likes_count += each.likes_count || 0;
                        summary_dict[brandName]['macro'].comments_count += each.comments_count || 0;
                        summary_dict[brandName]['macro'].count += each.count || 0;
                        summary_dict[brandName]['macro'].engagement__rate += each.engagement__rate || 0;
                        summary_dict[brandName]['macro'].view_count += each.view_count || 0;
                        summary_dict[brandName]['macro'].fair_price += each.fair_price || 0;
                    }
                } else if (each.creator_type == 'nano') {
                    if (!summary_dict[brandName]['nano']) {
                        summary_dict[brandName]['nano'] = each;
                    } else {
                        summary_dict[brandName]['nano'].creators += each.creators || 0;
                        summary_dict[brandName]['nano'].followers += each.followers || 0;
                        summary_dict[brandName]['nano'].likes_count += each.likes_count || 0;
                        summary_dict[brandName]['nano'].comments_count += each.comments_count || 0;
                        summary_dict[brandName]['nano'].count += each.count || 0;
                        summary_dict[brandName]['nano'].engagement__rate += each.engagement__rate || 0;
                        summary_dict[brandName]['nano'].view_count += each.view_count || 0;
                        summary_dict[brandName]['nano'].fair_price += each.fair_price || 0;
                    }
                } else if (each.creator_type == 'micro') {
                    if (!summary_dict[brandName]['micro']) {
                        summary_dict[brandName]['micro'] = each;
                    } else {
                        summary_dict[brandName]['micro'].creators += each.creators || 0;
                        summary_dict[brandName]['micro'].followers += each.followers || 0;
                        summary_dict[brandName]['micro'].likes_count += each.likes_count || 0;
                        summary_dict[brandName]['micro'].comments_count += each.comments_count || 0;
                        summary_dict[brandName]['micro'].count += each.count || 0;
                        summary_dict[brandName]['micro'].engagement__rate += each.engagement__rate || 0;
                        summary_dict[brandName]['micro'].view_count += each.view_count || 0;
                        summary_dict[brandName]['micro'].fair_price += each.fair_price || 0;
                    }
                } else if (each.creator_type == 'mega') {
                    if (!summary_dict[brandName]['mega']) {
                        summary_dict[brandName]['mega'] = each;
                    } else {
                        summary_dict[brandName]['mega'].creators += each.creators || 0;
                        summary_dict[brandName]['mega'].followers += each.followers || 0;
                        summary_dict[brandName]['mega'].likes_count += each.likes_count || 0;
                        summary_dict[brandName]['mega'].comments_count += each.comments_count || 0;
                        summary_dict[brandName]['mega'].count += each.count || 0;
                        summary_dict[brandName]['mega'].engagement__rate += each.engagement__rate || 0;
                        summary_dict[brandName]['mega'].view_count += each.view_count || 0;
                        summary_dict[brandName]['mega'].fair_price += each.fair_price || 0;
                    }
                } else if (each.creator_type == 'normal') {
                    if (!summary_dict[brandName]['normal']) {
                        summary_dict[brandName]['normal'] = each;
                    } else {
                        summary_dict[brandName]['normal'].creators += each.creators || 0;
                        summary_dict[brandName]['normal'].followers += each.followers || 0;
                        summary_dict[brandName]['normal'].likes_count += each.likes_count || 0;
                        summary_dict[brandName]['normal'].comments_count += each.comments_count || 0;
                        summary_dict[brandName]['normal'].count += each.count || 0;
                        summary_dict[brandName]['normal'].engagement__rate += each.engagement__rate || 0;
                        summary_dict[brandName]['normal'].view_count += each.view_count || 0;
                        summary_dict[brandName]['normal'].fair_price += each.fair_price || 0;
                    }
                }
            })
            results[index]['budget'] = (calculate_price_index(
                summary_dict[brandName].followers,
                summary_dict[brandName].likes_count,
                summary_dict[brandName].comments_count,
                1
            )/100000).toFixed(2);
            let resp_post = await getBase64HandlerData(results[index].ig_handle, '', 1, '', '' , '1', start_date, end_date, results[index].excluded_handles, results[index].included_insta_shortcodes);
            let resp_yt_post = await getBase64YtHandlerData(results[index].yt_handle, '', 1, start_date, end_date, '', '' , '1', results[index].excluded_channel_ids);
            resp_post = resp_post.results;
            resp_yt_post = resp_yt_post.results;
            if (resp_yt_post.length == 0) {
                summary_dict[brandName]['posts'] = resp_post.slice(0, 4);
            } else if (resp_post.length == 0) {
                summary_dict[brandName]['posts'] = resp_yt_post.slice(0, 4);
            } else if (resp_post.length == 1) {
                summary_dict[brandName]['posts'] = resp_post.concat(resp_yt_post.slice(0, 3));
            } else if (resp_yt_post.length == 1) {
                summary_dict[brandName]['posts'] = resp_yt_post.concat(resp_post.slice(0, 3));
            } else {
                summary_dict[brandName]['posts'] = resp_post.slice(0, 2).concat(resp_yt_post.slice(0, 2));
            }
            let creators = await creatorsFetch(results[index].ig_handle, start_date, end_date, '', '', results[0].ig_handle, results[index].excluded_handles);
            let ytCreators = await ytCreatorsFetch(results[index].yt_handle, start_date, end_date, '', '', results[0].yt_handle, results[index].excluded_channel_ids);
            creators = creators.results;
            ytCreators = ytCreators.results;
            let list = (results[index].excluded_handles && results[index].excluded_handles.split(",")) || []
            if (list.length > 0) {
                creators = creators.filter((each_creator)=>{
                    console.log(list.indexOf(each_creator.handle.replace('@', '')) == -1, list, each_creator.handle.replace('@', ''));
                    return list.indexOf(each_creator.handle.replace('@', '')) == -1
                })
            }
            let ytList = (results[index].excluded_channel_ids && results[index].excluded_channel_ids.split(",")) || []
            if (ytList.length > 0) {
                ytCreators = ytCreators.filter((each_creator)=>{
                    return ytList.indexOf(each_creator.channel_id) == -1
                })
            }
            if (ytCreators.length == 0) {
                summary_dict[brandName]['creators_profile'] = creators.slice(0, 4);
            } else if (creators.length == 0) {
                summary_dict[brandName]['creators_profile'] = ytCreators.slice(0, 4);
            } else if (creators.length == 1) {
                summary_dict[brandName]['creators_profile'] = creators.concat(ytCreators.slice(0, 3));
            } else if (ytCreators.length == 1) {
                summary_dict[brandName]['creators_profile'] = ytCreators.concat(creators.slice(0, 3));
            } else {
                summary_dict[brandName]['creators_profile'] = creators.slice(0, 2).concat(ytCreators.slice(0, 2));
            }
            let resp_data = await fetchInstaPostsOverTime(results[index].ig_handle, start_date, end_date, '', '');
            if (!summary_dict['posts_over_time_labels']) {
                summary_dict['posts_over_time_labels'] = {};
            }
            for (var i=0;i<resp_data.labels.length;i++) {
                let day = resp_data.labels[i];
                if (!summary_dict['posts_over_time_labels'][day]) {
                    summary_dict['posts_over_time_labels'][day] = 1;
                }
                if (!summary_dict[brandName]['post_count']) {
                    summary_dict[brandName]['post_count'] = {}
                }
                if (!summary_dict[brandName]['post_count'][day]) {
                    summary_dict[brandName]['post_count'][day] = resp_data.post_count[i];
                } else {
                    summary_dict[brandName]['post_count'][day] += resp_data.post_count[i];
                }
            }
            resp_data = await fetchYTPostsOverTime(results[index].yt_handle, start_date, end_date, '', '');
            if (!summary_dict['posts_over_time_labels']) {
                summary_dict['posts_over_time_labels'] = {};
            }
            for (var i=0;i<resp_data.labels.length;i++) {
                let day = resp_data.labels[i];
                if (!summary_dict['posts_over_time_labels'][day]) {
                    summary_dict['posts_over_time_labels'][day] = 1;
                }
                if (!summary_dict[brandName]['post_count']) {
                    summary_dict[brandName]['post_count'] = {}
                }
                if (!summary_dict[brandName]['post_count'][day]) {
                    summary_dict[brandName]['post_count'][day] = resp_data.post_count[i];
                } else {
                    summary_dict[brandName]['post_count'][day] += resp_data.post_count[i];
                }
            }
            if (index + 1 == results.length) {
                setSummary(summary_dict);
                setTotalViews(totalViews);
                setTotalEng(totalEng);
                var maxBudget = {};
                var budget = 0;
                results.map(each=>{
                    if (budget < parseFloat(each.budget)) {
                        budget = parseFloat(each.budget);
                        maxBudget = each;
                    }
                })
                setMaxBugetObj(maxBudget);
            } else {
                loadSummaryData(results, index+1, summary_dict, totalViews, totalEng);
            }
        }
    }

    const camelCase = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();
 
        return str.replace(/\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() +
                    txt.substr(1).toLowerCase();
            });
    }

    const getYMD = (date1, date2) => {
        const a = moment(date1);
        const b = moment(date2);
        var days = a.diff(b, 'days') + 1;
        // var years = a.diff(b, 'year');
        // b.add(years, 'years');
    
        // // const noOfDaysInb = b.daysInMonth();
        // // const noOfDaysIna = a.daysInMonth();
        // let months = 0;
        // months = a.diff(b, 'months');
        // b.add(months, 'months');
        // var days = a.diff(b, 'days') + 1;
        // let days_string = "";
        // if (months > 0) {
        //     days_string += `${months} months`
        //     if (days > 0) {
        //         days_string += ` ${days} days`
        //     }
        // } else {
        //     if (days > 0) {
        //         days_string += `${days} days`
        //     }
        // }
        return `${days} days`;
    }

    const getInfluencerType = (followers) => {
        if (followers >= 200 && followers <= 2000) {
            return "Normal User";
        } if (followers >= 2000 && followers <= 9999) {
            return "Nano Influencer";
        } else if (followers >= 10000 && followers <= 99999) {
            return "Micro Influencer";
        } else if (followers >= 100000 && followers <= 999999) {
            return "Macro Influencer";
        } else if (followers >= 1000000) {
            return "Mega Influencer"
        }
        return "Influencer"
    }
  
    return (
        <div ref={pdfRef}>
            <Grid container direction="column" style={{ background: '#f2f3fa', paddingTop: 10 }}  >
            <Grid item md={12}>
                <Grid container style={{ alignItems: 'center' }}>
                    <Grid item>
                        <img src='/static/media/logo.9bfbfb27.png' style={{ width: '200px', marginLeft: 20 }}/>
                    </Grid>
                    <Grid item style={{ flex: 1 }}>
                        <Typography style={{ color: '#00218d', fontSize: 25, fontWeight: 'bold', marginTop: 20, marginBottom: 10 }}>{camelCase(competitor_brands && competitor_brands.length > 0 ? competitor_brands && competitor_brands[0].title :'')} Competition Analysis report</Typography>
                    </Grid>
                    <Grid item style={{ marginRight: '20px' }}>
                    <Typography style={{
                        cursor:'pointer',
                        color:'#000000',
                    }} onClick={()=>{
                    const input = pdfRef.current;
                    html2canvas(input, {
                        allowTaint : false,
                        useCORS: true
                    }).then((canvas)=>{
                        var imgWidth = 210;
                        var pageHeight = 290;
                        var imgHeight = canvas.height * imgWidth / canvas.width;
                        var heightLeft = imgHeight;


                        var doc = new jsPDF('p', 'mm');
                        var position = 0;
                        var pageData = canvas.toDataURL('image/jpeg', 1.0);
                        var imgData = encodeURIComponent(pageData);
                        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                        doc.setLineWidth(0);
                        doc.setDrawColor(255, 255, 255);
                        doc.rect(0, 0, 210, 290);
                        heightLeft -= pageHeight;

                        while (heightLeft >= 0) {
                            position = heightLeft - imgHeight;
                            doc.addPage();
                            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                            doc.setLineWidth(0);
                            doc.setDrawColor(255, 255, 255);
                            doc.rect(0, 0, 210, 290);
                            heightLeft -= pageHeight;
                        }
                        doc.save(camelCase(competitor_brands && competitor_brands.length > 0 ? competitor_brands && competitor_brands[0].title :'')+' Competion Analysis report.pdf');
                    })
                    
                }}>Analysis Date: {moment(start_date).format('Do MMM')} - {moment(end_date).format('Do MMM')} ({getYMD(end_date, start_date)})</Typography>

                    </Grid>
                </Grid>
                
            </Grid>
            <Typography style={{ color: '#00218d', fontSize: 16, fontWeight: 'bold', marginTop: 10, marginBottom: 10 }}>Objective: To evaluate the effectiveness of influencer marketing campaigns implemented by {camelCase(competitor_brands && competitor_brands.length > 0 ? competitor_brands && competitor_brands[0].title :'')} and competitor brands by identifying the top content and Influencers</Typography>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 10, height: '790px' }} >
                <div style={{ width: '90%', background: 'white', padding: 20, borderRadius: 10 }}>
                    <div style={{ color: '#00218d', fontSize: 25, fontWeight: 'bold' }}>Summary</div>
                    <div style={{ color: '#00218d', fontSize: 25, fontWeight: 'bold', marginTop: 20 }}>Posts</div>
                    <table style={{ width: '100%', marginTop: 0, borderCollapse: 'collapse' }} cellpadding="10">
                        <thead>
                            <tr>
                                <th></th>
                                {
                                    competitor_brands && competitor_brands.map((each, index)=>{
                                        if (index == 0) {
                                            return <th>{camelCase(each.title)}</th>
                                        } 
                                        return <td>{each.title}</td>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ borderBottom: '1px solid #c6c6c6' }}>
                                <th style={{ textAlign: 'left' }}>Mega Influencers (1M - 27M)</th>
                                {
                                    competitor_brands && competitor_brands.map(each=>{
                                        return <td style={{ color: '#16adc4', fontWeight: 'bold' }}>{(summary[each.title] && summary[each.title]['mega'] && summary[each.title]['mega'].count) || "-"}</td>
                                    })
                                }
                            </tr>
                            <tr style={{ borderBottom: '1px solid #c6c6c6' }}>
                                <th style={{ textAlign: 'left' }}>Macro Influencers (100K - 1M)</th>
                                {
                                    competitor_brands && competitor_brands.map(each=>{
                                        return <td style={{ color: '#16adc4', fontWeight: 'bold' }}>{(summary[each.title] && summary[each.title]['macro'] && summary[each.title]['macro'].count) || "-"}</td>
                                    })
                                }
                            </tr>
                            <tr style={{ borderBottom: '1px solid #c6c6c6' }}>
                                <th style={{ textAlign: 'left' }}>Micro Influencers (10K - 100K)</th>
                                {
                                    competitor_brands && competitor_brands.map(each=>{
                                        return <td style={{ color: '#16adc4', fontWeight: 'bold' }}>{(summary[each.title] && summary[each.title]['micro'] && summary[each.title]['micro'].count) || "-"}</td>
                                    })
                                }
                            </tr>
                            <tr style={{ borderBottom: '1px solid #c6c6c6' }}>
                                <th style={{ textAlign: 'left' }}>Nano Influencers (2K - 10K)</th>
                                {
                                    competitor_brands && competitor_brands.map(each=>{
                                        return <td style={{ color: '#16adc4', fontWeight: 'bold' }}>{(summary[each.title] && summary[each.title]['nano'] && summary[each.title]['nano'].count) || "-"}</td>
                                    })
                                }
                            </tr>
                            <tr style={{ borderBottom: '1px solid #c6c6c6' }}>
                                <th style={{ textAlign: 'left' }}>Normal Users (200 - 2K)</th>
                                {
                                    competitor_brands && competitor_brands.map(each=>{
                                        return <td style={{ color: '#16adc4', fontWeight: 'bold' }}>{(summary[each.title] && summary[each.title]['normal'] && summary[each.title]['normal'].count) || "-"}</td>
                                    })
                                }
                            </tr>
                            <tr>
                                <th style={{ textAlign: 'left' }}>Total Posts</th>
                                {
                                    competitor_brands && competitor_brands.map(each=>{
                                        return <td style={{ color: '#16adc4', fontWeight: 'bold' }}>{(summary[each.title] && summary[each.title].count) || "-"}</td>
                                    })
                                }
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ color: '#00218d', fontSize: 25, fontWeight: 'bold', marginTop: 10 }}>Influencers</div>   
                    <table style={{ width: '100%', marginTop: 0, borderCollapse: 'collapse' }} cellpadding="10">
                        <thead>
                            <tr>
                                <th></th>
                                {
                                    competitor_brands && competitor_brands.map((each, index)=>{
                                        if (index == 0) {
                                            return <th>{camelCase(each.title)}</th>
                                        } 
                                        return <td>{each.title}</td>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ borderBottom: '1px solid #c6c6c6' }}>
                                <th style={{ textAlign: 'left', borderBottom: '1px solid #c6c6c6' }}>Mega Influencers (1M - 27M)</th>
                                {
                                    competitor_brands && competitor_brands.map(each=>{
                                        return <td style={{ color: '#16adc4', fontWeight: 'bold' }}>{(summary[each.title] && summary[each.title]['mega'] && summary[each.title]['mega'].creators) || "-"}</td>
                                    })
                                }
                            </tr>
                            <tr style={{ borderBottom: '1px solid #c6c6c6' }}>
                                <th style={{ textAlign: 'left' }}>Macro Influencers (100K - 1M)</th>
                                {
                                    competitor_brands && competitor_brands.map(each=>{
                                        return <td style={{ color: '#16adc4', fontWeight: 'bold' }}>{(summary[each.title] && summary[each.title]['macro'] && summary[each.title]['macro'].creators) || "-"}</td>
                                    })
                                }
                            </tr>
                            <tr style={{ borderBottom: '1px solid #c6c6c6' }}>
                                <th style={{ textAlign: 'left' }}>Micro Influencers (10K - 100K)</th>
                                {
                                    competitor_brands && competitor_brands.map(each=>{
                                        return <td style={{ color: '#16adc4', fontWeight: 'bold' }}>{(summary[each.title] && summary[each.title]['micro'] && summary[each.title]['micro'].creators) || "-"}</td>
                                    })
                                }
                            </tr>
                            <tr style={{ borderBottom: '1px solid #c6c6c6' }}>
                                <th style={{ textAlign: 'left' }}>Nano Influencers (2K - 10K)</th>
                                {
                                    competitor_brands && competitor_brands.map(each=>{
                                        return <td style={{ color: '#16adc4', fontWeight: 'bold' }}>{(summary[each.title] && summary[each.title]['nano'] && summary[each.title]['nano'].creators) || "-"}</td>
                                    })
                                }
                            </tr>
                            <tr style={{ borderBottom: '1px solid #c6c6c6' }}>
                                <th style={{ textAlign: 'left' }}>Normal Users (200 - 2K)</th>
                                {
                                    competitor_brands && competitor_brands.map(each=>{
                                        return <td style={{ color: '#16adc4', fontWeight: 'bold' }}>{(summary[each.title] && summary[each.title]['normal'] && summary[each.title]['normal'].creators) || "-"}</td>
                                    })
                                }
                            </tr>
                            <tr>
                                <th style={{ textAlign: 'left' }}>Total Influencers</th>
                                {
                                    competitor_brands && competitor_brands.map(each=>{
                                        return <td style={{ color: '#16adc4', fontWeight: 'bold' }}>{(summary[each.title] && summary[each.title].creators) || "-"}</td>
                                    })
                                }
                            </tr>
                        </tbody>
                    </table> 
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 0 }} >
                <div style={{ width: '90%', background: 'white', padding: 20, borderRadius: 10, marginTop: 0 }}>
                    <div style={{ color: '#00218d', fontSize: 25, fontWeight: 'bold' }}>Channel Wise Influencer Split</div>
                    <table style={{ width: '100%', marginTop: 10, borderCollapse: 'collapse' }} cellpadding="10">
                        <thead>
                            <tr>
                                <th></th>
                                {
                                    competitor_brands && competitor_brands.map((each, index)=>{
                                        if (index == 0) {
                                            return <th>{camelCase(each.title)}</th>
                                        } 
                                        return <td>{each.title}</td>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ borderBottom: '1px solid #c6c6c6' }}>
                                <th style={{ textAlign: 'left' }}>Instagram</th>
                                {
                                    competitor_brands && competitor_brands.map(each=>{
                                        return <td style={{ color: '#16adc4', fontWeight: 'bold' }}>{(summary[each.title] && summary[each.title]['instagram'] && summary[each.title]['instagram'].creators) || "-"}</td>
                                    })
                                }
                            </tr>
                            <tr style={{ borderBottom: '2px solid #00218d' }}>
                                <th style={{ textAlign: 'left' }}>Youtube</th>
                                {
                                    competitor_brands && competitor_brands.map(each=>{
                                        return <td style={{ color: '#16adc4', fontWeight: 'bold' }}>{(summary[each.title] && summary[each.title]['youtube'] && summary[each.title]['youtube'].creators) || "-"}</td>
                                    })
                                }
                            </tr>
                            <tr>
                                <th style={{ textAlign: 'left', color: '#00218d', fontWeight: 'bold' }}>Total</th>
                                {
                                    competitor_brands && competitor_brands.map(each=>{
                                        return <td style={{ color: '#00218d', fontWeight: 'bold' }}>{(summary[each.title] && summary[each.title].creators) || "-"}</td>
                                    })
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ width: '90%', background: 'white', padding: 20, borderRadius: 10, marginTop: 30, marginBottom: 30 }}>
                    <div style={{ color: '#00218d', fontSize: 22, fontWeight: 'bold' }}>ESTIMATED BUDGETS BEING SPENT BY BRANDS (IN LAKHS)</div>
                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginBottom: 10 }}>
                        <div style={{ flex: 1.5 }}>
                            <Bar
                                data={{
                                    labels: competitor_brands && competitor_brands.map(each=>{ return each.title }),
                                    datasets: [
                                        {
                                            label: '',
                                            data: (competitor_brands && competitor_brands.map(each=>{ return parseFloat(each.budget) }) || []),
                                            backgroundColor: '#00218d',
                                        },
                                    ],
                                }}
                                options={{
                                    indexAxis: 'y'
                                }}
                            />
                        </div>
                        <div style={{ flex: 1.5 }}>
                            <div style={{ color: '#00218d', fontSize: 60, fontWeight: 'bold' }}>{maxBugetObj.budget} Lakhs</div>  
                            <div  style={{ color: '#000000', fontSize: 20 }}>The highest budget being spent by {maxBugetObj.title}</div>
                        </div>
                    </div>
                    <div style={{ color: '#00218d', fontSize: 14 }}>
                    Please note: Estimated budget based on Fair Price Index of Influencer.in and may differ from actual spends
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: 100 }}>
                    <div style={{ width: '40%', background: 'white', padding: 20, marginRight: "calc(10% - 40px)", borderRadius: 10 }}>
                        <div style={{ color: '#00218d', fontSize: 20, fontWeight: 'bold', marginBottom: 10 }}>Campaign Performance by Views</div>
                        <Bar
                            data={{
                                labels: competitor_brands && competitor_brands.map(each=>{ return each.title }),
                                datasets: [
                                    {
                                        label: '',
                                        data: (competitor_brands && competitor_brands.map(each=>{ return summary[each.title] && summary[each.title].view_count }) || []),
                                        backgroundColor: '#00218d',
                                    },
                                ],
                            }}
                            options={{
                                indexAxis: 'y'
                            }}
                        />                 
                    </div>
                    <div style={{ width: '40%', background: 'white', padding: 20, borderRadius: 10 }}>
                        <div style={{ color: '#00218d', fontSize: 20, fontWeight: 'bold', marginBottom: 10 }}>Campaign Performance by Engagement Percentage %</div>
                        <Bar
                            data={{
                                labels: competitor_brands && competitor_brands.map(each=>{ return each.title }),
                                datasets: [
                                    {
                                        label: '',
                                        data: (competitor_brands && competitor_brands.map(each=>{ return summary[each.title] && summary[each.title].engagement__rate }) || []),
                                        backgroundColor: '#00218d',
                                    },
                                ],
                            }}
                            options={{
                                indexAxis: 'y'
                            }}
                        />                  
                    </div>
                </div>
                    {
                        competitor_brands && competitor_brands.map((each, index)=>{
                            return <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', height: '1390px' }}>
                                <div style={{ width: '90%', background: 'white', padding: 20, borderRadius: 30, marginBottom: 20, background: '#00218d' }}> 
                                    <div style={{ color: '#FFFFFF', fontSize: 20, fontWeight: 'bold' }}>{each.title} - Top Content by Views</div>     
                                </div>
                                {
                                    summary[each.title] && summary[each.title]['posts'] && summary[each.title]['posts'].map(each=>{
                                        let isYoutube = 'yt_thumnail_url' in each;
                                        return <div style={{ width: '90%', background: 'white', padding: 20, borderRadius: 10, marginBottom: 20, display: 'flex', alignItems: 'center' }}> 
                                            <div>
                                                <ImageValidator
                                                    className={classes.image} 
                                                    url={'data:image/jpeg;base64,'+each.base_image} 
                                                    altText={"icon"}
                                                    placeContainerHolderStyle={{ marginBottom: '5px' }}>
                                                    <Grid 
                                                        container 
                                                        justifyContent='center' 
                                                        alignItems='center' 
                                                        className={classes.image} 
                                                        style={{background:'#ffffff'}}>
                                                        <Grid item>
                                                            <img style={{width:100,height:100}} src={insta_placeholder}  alt="icon" />
                                                        </Grid>
                                                    </Grid>
                                                </ImageValidator>
                                            </div>
                                            <div style={{ flex: 1, marginLeft: 10, marginRight: 10, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                    <a target='_blank' style={{ height: '25px', overflow: 'hidden', marginBottom: '5px' }}>{each.caption || each.title}</a>
                                                    <div style={{ display: 'flex' }}>
                                                        <Button 
                                                            onClick={()=>{
                                                                updateProfileData({
                                                                    handle: each.creator_details.id,
                                                                    brand_id: brand_id,
                                                                    profile_type: "channel_id" in each ? 'youtube' : 'instagram', 
                                                                })
                                                            }}
                                                            variant="outlined" 
                                                            style={{
                                                                background: '#1E9CB2',
                                                                borderColor: '#1E9CB2',
                                                                marginRight: '10px',
                                                                color: 'white',
                                                                marginTop: '5px',
                                                            }}>Update Profile</Button>
                                                        <Button
                                                            onClick={()=>{
                                                                updatePostData({
                                                                    post_id: each.id,
                                                                    post_type: isYoutube ? 'youtube' : 'instagram',
                                                                })
                                                            }}
                                                            variant="outlined" 
                                                            style={{
                                                                background: '#1E9CB2',
                                                                borderColor: '#1E9CB2',
                                                                marginRight: '10px',
                                                                color: 'white',
                                                                marginTop: '5px',
                                                            }}>Update Post</Button>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                        <img style={{width: 30, marginRight: 5}} src={isYoutube ? YoutubeIcon : InstagramIcon}  alt="icon" />
                                                        {each.creator_details.name}
                                                        <div style={{ width: '1002px', textAlign: 'center', position: 'absolute' }}>Post Link: {isYoutube ? `https://www.youtube.com/watch?v=${each.video_id}/` : `https://www.instagram.com/p/${each.shortcode}`}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}><PersonOutlineOutlinedIcon style={{ marginRight: 5}} />{countertostring(each.creator_details.followers)} ({getInfluencerType(each.creator_details.followers)})</div>
                                                </div>
                                            </div>
                                            <div style={{ width: 100, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img src={ViewIcon} alt="ViewIcon" className={classes.icon} style={{ width: 20, marginRight: 5 }}/>
                                                    {countertostring(each.view_count)}
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <ThumbUpOutlinedIcon />
                                                    {countertostring(each.likes_count)}
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img src={CommentIcon} alt="commenticon" className={classes.icon} style={{ width: 20, marginRight: 5 }}/>
                                                    {countertostring(each.comments_count)}
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img src={EngagementRateIcon} alt="commenticon" className={classes.icon} style={{ width: 20, marginRight: 5 }}/>
                                                    {parseFloat(each.new_engagement_rate || 
                                                        calculateEngagementRate(each.likes_count, each.comments_count, 0, each.view_count)).toFixed(2)}
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        })
                    }
                    <div style={{ height: '160px', width: '100%', display: 'block' }}>

                    </div>
                    {
                        competitor_brands && competitor_brands.map((each, index)=>{
                            return <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <div style={{ width: '90%', background: 'white', padding: 20, borderRadius: 30, marginBottom: 20, background: '#00218d' }}> 
                                    <div style={{ color: '#FFFFFF', fontSize: 20, fontWeight: 'bold' }}>{index == 0 ? 'Top' : 'Same'} influencer partnered {index == 0 ? '' : 'with competitor'} - {each.title}</div>     
                                </div>
                                <div style={{ display: 'flex', width: '90%', background: '#FFFFFF', borderRadius: 10, marginBottom: 20 }}>
                                    {
                                        summary[each.title] && summary[each.title]['creators_profile'] && summary[each.title]['creators_profile'].map(each=>{
                                            return <div style={{ flex: 1, marginRight: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: 10, border: '1px solid #000000', borderRadius: 20 }}>
                                                <div style={{ marginTop: 10, fontWeight: 'bold', fontSize: 20 }}>{"channel_id" in each ? each.display_name : '-'}</div>
                                                <ImageValidator
                                                    className={classes.image} 
                                                    url={each.base_image ? 'data:image/jpeg;base64,'+each.base_image : each.thumbnail_url || ''} 
                                                    altText={"icon"}
                                                    placeContainerHolderStyle={{ marginBottom: '5px' }}
                                                    >
                                                    <Grid 
                                                        container 
                                                        justifyContent='center' 
                                                        alignItems='center' 
                                                        className={classes.image} 
                                                        style={{background:'#ffffff'}}>
                                                        <Grid item>
                                                            <img style={{width:100,height:100}} src={insta_placeholder}  alt="icon" />
                                                        </Grid>
                                                    </Grid>
                                                </ImageValidator>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10 }}>
                                                    <img style={{width: 30, marginRight: 5}} src={"channel_id" in each ? YoutubeIcon : InstagramIcon}  alt="icon" />
                                                    {"channel_id" in each ? each.name : each.handle}
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: 10 }}>
                                                        <PersonOutlineOutlinedIcon />
                                                        {countertostring(each.followers)}
                                                    </div>
                                                </div>
                                                <Button 
                                                    onClick={()=>{
                                                        updateProfileData({
                                                            handle: each.handle,
                                                            brand_id: brand_id,
                                                            profile_type: "channel_id" in each ? 'youtube' : 'instagram', 
                                                        })
                                                    }}
                                                    variant="outlined" 
                                                    style={{
                                                        background: '#1E9CB2',
                                                        borderColor: '#1E9CB2',
                                                        marginRight: '10px',
                                                        color: 'white',
                                                        marginTop: '5px',
                                                        cursor: 'pointer'
                                                    }}>Update Profile</Button>
                                            </div>
                                        })
                                    }
                                    {
                                        summary[each.title] ? summary[each.title]['creators_profile'] && summary[each.title]['creators_profile'].length > 0 ?
                                        null : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%' }}>No data available to show</div> : null
                                    }
                                </div>
                            </div>
                        })
                    }
                    <div style={{ height: '1100px', width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 67 }}>
                            <div style={{ width: '90%', background: 'white', padding: 20, borderRadius: 30, marginBottom: 20, background: '#00218d' }}> 
                                <div style={{ color: '#FFFFFF', fontSize: 20, fontWeight: 'bold' }}>Posts Over Time</div>     
                            </div>
                        </div>
                        <div style={{ width: '90%' }}>
                            <Line
                                height={240}
                                width={700}
                                data={{
                                labels: Object.keys(summary['posts_over_time_labels'] || {}).sort((a, b)=>{
                                    return new Date(a) - new Date(b);
                                }),
                                datasets: competitor_brands && competitor_brands.map((each, index)=>{
                                    return {
                                        label: each.title,
                                        data: Object.keys(summary['posts_over_time_labels'] || {}).sort((a, b)=>{
                                            return new Date(a) - new Date(b);
                                        }).map(each_month=>{
                                            return (summary[each.title] && summary[each.title]['post_count'] && summary[each.title]['post_count'][each_month]) || 0
                                        }),
                                        fill: false,
                                        backgroundColor: colourPalette[index],
                                        borderColor: colourPalette[index],
                                    }})
                                }}
                                options={{
                                indexAxis: 'x',
                                scales: {
                                    y: {
                                    beginAtZero: true
                                    }
                                }
                                }}
                            />
                        </div>
                    </div>
                    <div style={{  width: '90%', height: '900px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div style={{ fontWeight: 'bold', fontSize: 40 }}>Thank You</div>
                        <div style={{ fontWeight: 'bold', fontSize: 20 }}>In case of any queries please reach us at enquiry@influencer.in</div>
                    </div>
                    <div style={{ background: '#f2f3fa', height: '100px', width: '100%', display: 'block' }}>

                    </div>
                </div>
            </Grid>
        </div>
    );
}

const styles = (theme) => ({
  header: {
    color: '#4A4A4A',
    fontSize: '30px',
    textAlign: 'left',
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    lineHeight: 1.167,
    letterSpacing: '0.03em',
    padding: '15px',
    background: '#F3F4FE',
    boxShadow: '0px 4px 20px rgba(170, 170, 170, 0.25)',
    borderRadius: '10px',
    marginBottom: '0px',
    marginTop: '20px',
    marginLeft: '27px',
    marginRight: '40px',
    textAlign: 'center'
  },
  image: {
    height: 220,
    width: 220,
    borderRadius: 20,
    marginTop: 10,
    textAlign: 'center',
    cursor: 'pointer',
  },
});

export default withStyles(styles)(CompetitorReportAnalysisUI);