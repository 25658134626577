import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, Button, Alert, IconButton } from '@mui/material';

import { gridSpacing } from 'store/constant';

import InfluencerList from '../InfluencerList';

// import { getPlannerInfluencersES } from 'api_handlers/es';
import { fetchPlanInfluencers } from 'api_handlers/planner2';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { SearchOff } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import { downloadStatus } from 'api_handlers/planner2';
const PlanInfluencers = ({ match: { params } }) => {
  const [influencers, setInfluencers] = useState([]);
  const [count, setCount] = useState(0);
  const [platform, setPlatform] = useState('all');
  const [sortBy, setSortBy] = useState('followers');
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [errorMassage, setErrorMassage] = useState(false);
  const [successMassage, setSuccessMassage] = useState(false);
  const [downloadReport, setDownloadReport] = useState(false);
  const [successCount, setSuccessCount] = useState(0);

  const { planUUID } = params;
  const { listName } = params;
  const location = useLocation();
  const message = location.state?.text || 'noAuth';
  const planPlatform = location.state?.platform || 'noAuth';
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    console.log('platform PlanInfluencer List: ', platform);
    fetchPlanInfluencers(planUUID, platform, page, sortBy, search).then((response) => {
      console.log('RESPONSE: ', response);
      setCount(response.count);
      setInfluencers(
        response.results.map((profile) => {
          return profile;
        }),
      );
    });
    // fetchInfluencers(1)
  }, [page, platform, sortBy, search, successCount]);

  const handleDiscoverClick = () => {
    history.push('/planner/discover');
  };

  const handlePlatformChange = (newPlatform) => {
    setPlatform(newPlatform);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSortChange = (newSortBy) => {
    setSortBy(newSortBy);
  };

  const handleSearch = (newSearch) => {
    if (newSearch.length > 2) {
      setSearch(newSearch);
    }
    if (newSearch.length <= 2) {
      setSearch('');
    }
  };

  useEffect(() => {
    if (successMassage) {
      setErrorMassage(false);
    }
  }, [successMassage]);

  const noInfluencers = influencers.length === 0;

  return (
    <Grid container spacing={gridSpacing}>
      {errorMassage ? (
        <Alert
          sx={{
            background: '#fff7e2',
            mt: 1,
            ml: '25px',
            height: '80px',
            width: '100%',
            color: '#ff0000',
            fontSize: '16px',
          }}
          severity="error"
        >
          Some influencer links encountered errors during upload please resolve them and reupload.{' '}
          <span
            style={{ cursor: 'pointer', color: '#ff0000' }}
            onClick={async () => {
              downloadStatus(planUUID).then((res) =>
                window.open(res.data.response_sheet_url, '_blank'),
              );
              setDownloadReport(true);
            }}
          >{`[Download error report].`}</span>
        </Alert>
      ) : successMassage ? (
        <Alert
          sx={{
            mt: 1,
            ml: '25px',
            height: '80px',
            width: '100%',
            fontSize: '16px',
          }}
          severity="success"
          action={
            <IconButton onClick={() => setSuccessMassage(false)}>
              <CloseIcon sx={{ color: '#00dd00', mb: '2px', mr: '5px' }}></CloseIcon>
            </IconButton>
          }
        >
          All influencers are added successfully
        </Alert>
      ) : (
        ''
      )}
      <Grid item xs={12} md={12}>
        <InfluencerList
          influencers={influencers}
          totalResults={count}
          isAuth={message}
          onPlatformChange={handlePlatformChange}
          noInfluencers={noInfluencers}
          listName={decodeURIComponent(listName)}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          onSearch={handleSearch}
          planPlatform={planPlatform}
          planUUID={planUUID}
          errorMassage={(val) => setErrorMassage(val)}
          successMassage={(val) => setSuccessMassage(val)}
          downloadReport={downloadReport}
          successCount={(val) => setSuccessCount(val)}
        />
      </Grid>
    </Grid>
  );
};

export default PlanInfluencers;
