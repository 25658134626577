import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';

import MainCard from 'ui-component/cards/MainCard';
import { useHistory } from 'react-router-dom';

// project imports
import UserList from './UserList';
import PlannerUserList from './PlannerUserList';
import AddToCampaignModal from './AddToCampaignModal';
import { InputAdornment, Modal, Slide } from '@material-ui/core';
import Tick from '../../../../assets/discovery/tick.png';
import ListIcon from '../../../../assets/discovery/list-icon.svg';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { gridSpacing } from 'store/constant';
import { getPlannerDownload } from 'api_handlers/discovery';
import { showPremiumFeatureDialog, fetchInfluencersAction, updatePage } from 'actions/planner2';
import { updateFilters } from 'actions/planner-filters';
import empty from '../../../../assets/discovery/empty.jpg';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

import mixpanel from 'mixpanel-browser';
import { OutlinedInput, SnackbarContent } from '@mui/material';
import AddInfluencerModal from './AddInfluencerModal';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { colabImportStatus } from 'api_handlers/planner2';

const scrollToTop = async () => {
  window.scrollTo({
    top: 0,
    behavior: 'auto', // This enables smooth scrolling
  });
};

// ==============================|| USER LIST STYLE 1 ||============================== //

const InfluencerList = ({
  influencers,
  totalResults,
  loading,
  isAuth,
  onPlatformChange,
  noInfluencers,
  listName,
  onPageChange,
  onSortChange,
  onSearch,
  isDiscover,
  planPlatform,
  useCategorySort,
  planUUID,
  errorMassage,
  downloadReport,
  successMassage,
  successCount,
  // updateFilters,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.planner2.user);
  const page = useSelector((state) => state.planner2.page);
  const selectedInfluencers = useSelector((state) => state.planner2.selectedInfluencers);
  const [platform, setPlatform] = React.useState('all');
  const [sortBy, setSortBy] = React.useState(useCategorySort ? 'category' : 'followers');
  const [openAddToCampaign, setOpenAddToCampaign] = React.useState(false);
  const [openModalCondition, setOpenModalCondition] = useState('');
  const [buttonVariant, setButtonVariant] = useState('outlined');
  const [addToCampaignInfluencers, setAddToCampaignInfluencers] = React.useState([]);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [bulkUploadingStatus, setBulkUploadingStatus] = useState(false);
  const [searchInfluencer, setSearchInfluencer] = useState('');
  const [openAddInfluencerModal, setOpenAddInfluencerModal] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({});
  const [statusData, setStatusData] = useState({
    total_count: 1,
    success_count: 0,
    failed_count: 0,
  });
  const history = useHistory();
  // const [localPayload, setLocalPayload] = React.useState({...payload})

  const conditionalDispatch = (action) => {
    if (isAuth !== 'noAuth') {
      dispatch(action);
    }
  };

  const updatePageWithoutDispatch = (updatedPage) => {
    onPageChange(updatedPage);
  };

  const handleSortByChange = (event) => {
    const newSortBy = event.target.value;
    setSortBy(newSortBy);
    if (isAuth === 'planner' || isAuth === 'noAuth') {
      onSortChange(newSortBy);
    }
  };

  useEffect(() => {
    conditionalDispatch(updateFilters('sortBy', sortBy, true, true));
  }, [sortBy]);

  const handleDownload = () => {
    getPlannerDownload({
      selected_ids: Array.from(selectedInfluencers),
      objective: '',
      prediction: {},
      contacts: 'not_req',
      selectedColumns: [],
    });
  };

  const handleAddToCampaign = (influencerIds, isAuth) => {
    setOpenModalCondition(isAuth);
    const infs = influencers.filter((inf) => influencerIds.includes(inf.id));
    setAddToCampaignInfluencers(infs);
    setOpenAddToCampaign(true);
  };

  const handlePlatformChange = (event) => {
    const newPlatform = event.target.value;
    setPlatform(newPlatform);
    onPlatformChange(newPlatform);
  };

  const handleDiscoverClick = () => {
    if (process.env.NODE_ENV === 'production') {
      mixpanel.track('collab_list_add_influencer');
    }
    history.push('/planner/discover');
  };
  const handleShareClick = () => {
    // Copy the current page URL to the clipboard
    // navigator.clipboard.writeText(window.location.href);
    setButtonVariant('contained');
    setShareDialogOpen(true);
    navigator.clipboard.writeText(window.location.href);
    // setTimeout(() => setButtonVariant('outlined'), 2000);
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="right" />;
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShareDialogOpen(false);
  };

  const handleCLoseBulkStatus = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setBulkUploadingStatus(false);
  };

  function handleAddInfluencers() {
    if (isAuth === 'noAuth') {
      history.push('/signup');
    } else {
      setOpenAddInfluencerModal(true);
    }
  }
  // function handleAddInfluencersClose() {
  //   setAddInfluencerModal(false);
  // }

  useEffect(() => {
    if (isAuth === 'planner') {
      if (!isDiscover) {
        try {
          const uploadingStatus = setInterval(() => {
            colabImportStatus(planUUID).then((resp) => {
              setUploadStatus(resp);
              // console.log('response', resp);
              if (resp.status === 'processing') {
                setBulkUploadingStatus(true);
                setStatusData((data) => ({
                  ...data,
                  total_count: resp.total_count,
                  success_count: resp.success_count,
                  failed_count: resp.failed_count,
                }));
              }
              if (
                resp.status === 'completed' ||
                resp.status === 'Draft' ||
                resp.success_count + resp.failed_count === resp.total_count
              ) {
                // setBulkUploadingStatus(false);
                colabImportStatus(planUUID).then(async (resp) => {
                  await setUploadStatus(resp);
                  if (resp.status === 'completed') {
                    setStatusData((data) => ({
                      ...data,
                      total_count: resp.total_count,
                      success_count: resp.success_count,
                      failed_count: resp.failed_count,
                    }));
                  }
                });
                clearInterval(uploadingStatus);
              }
            });
          }, 500);
        } catch (e) {
          console.log('error', e);
          throw e;
        }
      }
    }
  }, []);

  useEffect(() => {
    if (statusData.failed_count > 0) {
      errorMassage(true);
    }
    if (
      bulkUploadingStatus &&
      statusData.total_count > 0 &&
      statusData.success_count === statusData.total_count
    ) {
      successMassage(true);
    }
    if (statusData.success_count + statusData.failed_count < statusData.total_count) {
      setOpenAddInfluencerModal(false);
    }
    if (!isDiscover) {
      successCount(statusData.success_count);
    }
  }, [statusData.failed_count, statusData.success_count, statusData.total_count]);
  return (
    <MainCard
      sx={{
        '& .MuiCardHeader-root': {
          height: 70,
        },
      }}
      title={
        <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
          {openAddInfluencerModal ? (
            <AddInfluencerModal
              open={openAddInfluencerModal}
              close={(val) => setOpenAddInfluencerModal(val)}
              planPlatform={planPlatform}
              planUUID={planUUID}
              bulkUploadingStatus={(val) => setBulkUploadingStatus(val)}
              errorMassage={(val) => errorMassage(val)}
              downloadReport={downloadReport}
              statusData={(val) =>
                setStatusData((data) => ({
                  ...data,
                  total_count: val.total_count,
                  success_count: val.success_count,
                  failed_count: val.failed_count,
                }))
              }
            >
              {' '}
            </AddInfluencerModal>
          ) : (
            ''
          )}
          <Grid item md={3}>
            <Typography variant="h3">
              {isAuth === 'planner' || isAuth === 'noAuth' ? listName : 'INFLUENCERS'} (
              {parseInt(totalResults).toLocaleString('en-IN')})
            </Typography>
          </Grid>

          <Grid item>
            <Grid container alignItems="center" spacing={0}>
              {!isDiscover ? (
                <Grid item>
                  <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                    <OutlinedInput
                      placeholder="Search by influencer name"
                      onChange={(event) => {
                        onSearch(event.target.value);
                        // setSearchInfluencer(event.target.value);
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon></SearchIcon>
                        </InputAdornment>
                      }
                    ></OutlinedInput>
                  </FormControl>
                </Grid>
              ) : (
                ''
              )}
              {isAuth === 'planner' || isAuth === 'noAuth' ? (
                <Grid item>
                  <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                    <InputLabel id="platform-select">Platform</InputLabel>
                    <Select
                      labelId="platform-select"
                      id="platform"
                      name="platform"
                      value={platform}
                      onChange={(event) => handlePlatformChange(event)}
                      label="Platform"
                    >
                      <MenuItem value={'all'}>All Platforms</MenuItem>
                      <MenuItem value={'instagram'}>Instagram</MenuItem>
                      <MenuItem value={'youtube'}>YouTube</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                ''
              )}

              <Grid item>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="age-select">Sort by</InputLabel>
                  <Select
                    labelId="platform-select"
                    id="sortBy"
                    name="sortBy"
                    value={sortBy}
                    onChange={handleSortByChange}
                    label="Sort By"
                  >
                    {useCategorySort && <MenuItem value={'category'}>Category</MenuItem>}
                    <MenuItem value={'followers'}>Followers</MenuItem>
                    <MenuItem value={'views'}>Avg Views</MenuItem>
                    <MenuItem value={'eng-rate'}>ER</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {isAuth === 'planner' || (isAuth === 'noAuth' && !noInfluencers) ? (
                <Grid item>
                  <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                    <InputLabel id="age-select">More</InputLabel>
                    <Select
                      labelId="platform-select"
                      id="Action"
                      name="Action"
                      value={'Action'}
                      // onChange={handleSortByChange}
                      label="Action"
                    >
                      <MenuItem sx={{ display: 'none' }} value={'Action'}>
                        More Actions
                      </MenuItem>
                      <MenuItem onClick={handleShareClick}>
                        <ShareIcon sx={{ height: 18, mr: '10px' }}></ShareIcon> Share List
                      </MenuItem>
                      {/* <MenuItem onClick={handleAddInfluencers}>
                        <AddIcon sx={{ height: 20, mr: '10px' }}></AddIcon> Add Influencers
                      </MenuItem> */}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      }
      content={false}
    >
      {noInfluencers ? (
        <>
          <img src={empty} style={{ width: '300px' }}></img>
          <Typography variant="h6" color="textSecondary" sx={{ textAlign: 'center', marginTop: 4 }}>
            Add Influencers from Discover
          </Typography>
          <Button
            variant="contained"
            color="primary"
            style={{ cursor: 'pointer' }}
            onClick={handleDiscoverClick}
          >
            Add Influencers
          </Button>
        </>
      ) : (
        // Otherwise show the respective list based on the isAuth prop
        <>
          {isAuth === 'planner' || isAuth === 'noAuth' ? (
            <PlannerUserList
              influencers={influencers}
              loading={loading}
              handleAddToCampaign={handleAddToCampaign}
              platform={platform}
              openShareDialog={shareDialogOpen}
              setShareDialogOpen={setShareDialogOpen}
              listName={listName}
              isAuth={isAuth}
            />
          ) : (
            <UserList
              influencers={influencers}
              loading={loading}
              handleAddToCampaign={handleAddToCampaign}
            />
          )}
        </>
      )}

      {/* {text === 'planner'?
            <PlannerUserList 
                influencers={influencers} 
                loading={loading} 
                handleAddToCampaign={handleAddToCampaign}
                platform={platform} 
            />:
            <UserList influencers={influencers} loading={loading} handleAddToCampaign={handleAddToCampaign} />} */}
      <Grid item xs={12} sx={{ p: 3 }}>
        <Grid container justifyContent="space-between" spacing={gridSpacing}>
          <Grid item>
            <Pagination
              count={Math.ceil(totalResults / 20)}
              color="primary"
              page={page}
              onChange={(e, updatedPage) => {
                if (user.premium_user || updatedPage < 4) {
                  if (isAuth === 'planner' || isAuth === 'noAuth') {
                    updatePageWithoutDispatch(updatedPage);
                  }
                  conditionalDispatch(updatePage(updatedPage));
                  conditionalDispatch(fetchInfluencersAction(updatedPage));
                  scrollToTop();
                  if (process.env.NODE_ENV === 'production') {
                    if (isDiscover) {
                      mixpanel.track(`Discover - Page clicked`, {
                        page: updatedPage,
                      });
                    } else {
                      mixpanel.track(`Page clicked`, {
                        page: updatedPage,
                      });
                    }
                  }
                } else conditionalDispatch(showPremiumFeatureDialog(true));
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={selectedInfluencers.size > 0 && !openAddToCampaign}
      >
        <Box sx={{ p: 2, bgcolor: '#111936', borderRadius: 1, minWidth: 800 }}>
          <Grid container alignItems={'center'} justifyContent={'center'} spacing={3}>
            <Grid item xs={'auto'}>
              <Typography variant="h4" sx={{ color: '#ffffff' }}>
                Selected {selectedInfluencers.size} influencers
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  // if (user.premium_user) {
                  handleAddToCampaign(Array.from(selectedInfluencers), 'Planner');
                  // } else {
                  // conditionalDispatch(showPremiumFeatureDialog(true));
                  // }
                }}
                startIcon={<img src={ListIcon} style={{ width: '12px' }}></img>}
              >
                Add to List
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  if (user.premium_user) {
                    handleAddToCampaign(Array.from(selectedInfluencers), 'AddToCampaign');
                  } else {
                    conditionalDispatch(showPremiumFeatureDialog(true));
                  }
                }}
                startIcon={<WorkspacePremiumIcon />}
              >
                Add to Campaign
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  if (user.premium_user) {
                    handleDownload();
                  } else {
                    conditionalDispatch(showPremiumFeatureDialog(true));
                  }
                }}
                startIcon={<WorkspacePremiumIcon />}
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Snackbar>
      <Snackbar
        open={shareDialogOpen}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
        message={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <img src={Tick} style={{ marginRight: 8, width: '23px' }}></img>
            {/* <CheckCircleIcon  /> */}
            url is copied
          </span>
        }
        autoHideDuration={1500}
      />
      <Snackbar
        open={bulkUploadingStatus}
        onClose={handleCLoseBulkStatus}
        // TransitionComponent={SlideTransition}
        // autoHideDuration={
        //   statusData.failed_count + statusData.success_count === statusData.total_count ||
        //   uploadStatus.success_count + uploadStatus.failed_count === uploadStatus.total_count
        //     ? 1000
        //     : null
        // }
      >
        <SnackbarContent
          sx={{
            background: '#fff',
            padding: '0px',
            maxWidth: '360px',
            '& .MuiSnackbarContent-message': {
              padding: '0px',
            },
          }}
          message={
            <Grid container width={'360px'} alignItems={'center'}>
              <Grid
                item
                width={'40px'}
                height={'80px'}
                sx={{
                  background: '#f2c263',
                  borderTopLeftRadius: '4px',
                  borderBottomLeftRadius: '4px',
                }}
              >
                <Grid
                  container
                  width={'100%'}
                  height={'100%'}
                  alignItems={'center'}
                  justifyContent="center"
                >
                  <Grid item>
                    <FileUploadIcon sx={{ color: '#000' }}></FileUploadIcon>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item width={'280px'} sx={{ background: '#fff' }}>
                <Grid container direction={'column'} alignItems={'start'}>
                  <Grid item>
                    <Typography
                      sx={{ color: '#000', fontWeight: 600, fontSize: 18, marginLeft: '10px' }}
                    >
                      Uploading Status
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ color: '#a0a0a0', marginLeft: '10px', fontSize: 13 }}>
                      Influencers Added Successfully :{' '}
                      {`${statusData.success_count}/${statusData.total_count}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ color: '#a0a0a0', marginLeft: '10px', fontSize: 13 }}>
                      Influencers Failed to add :{' '}
                      {`${statusData.failed_count}/${statusData.total_count}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  {statusData.total_count > 0 &&
                  statusData.failed_count + statusData.success_count === statusData.total_count ? (
                    <IconButton onClick={() => setBulkUploadingStatus(false)}>
                      <CloseIcon></CloseIcon>
                    </IconButton>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </Grid>
          }
        ></SnackbarContent>
      </Snackbar>
      {isAuth !== 'noAuth' && (
        <AddToCampaignModal
          open={openAddToCampaign}
          setOpen={setOpenAddToCampaign}
          influencers={addToCampaignInfluencers}
          text={openModalCondition}
        />
      )}
    </MainCard>
  );
};

export default InfluencerList;
