
import { useSelector } from "react-redux";

import { constants } from "constants/planner2";

export const GetFilterValue = (key) => {
  const payload = useSelector((state) => state.planner2.payload);
  const keys = constants.PAYLOAD_PATH_MAPPING[key].split('.');
  let current = payload;

  for (let i = 0; i < keys.length; i++) {
    current = current[keys[i]];
    if (!current) {
      break;
    }
  }
  if(current === undefined) {
    return null;
  }

  return current;
}

export const GetFilterValueV2 = (payload, key) => {
  const keys = constants.PAYLOAD_PATH_MAPPING[key].split('.');
  let current = payload;

  for (let i = 0; i < keys.length; i++) {
    current = current[keys[i]];
    if (!current) {
      break;
    }
  }
  if(current === undefined) {
    return null;
  }

  return current;
}

export const youTubeIdentifier = (text) => {
  const channelIdRegex = /^UC[a-zA-Z0-9_-]{22}$/;

  if (channelIdRegex.test(text)) {
    return 'channelId';
  } else {
    return 'handle';
  }
};

export const isValidURL = (string, domain) => {
  const instagramRegex = /^https:\/\/www\.instagram\.com\/.+$/;
  const youtubeRegex =  /^(https?:\/\/)?(www\.)?youtube\.com\/((user|channel|c|@)\/[a-zA-Z0-9_-]+(\/(about|videos|community|featured)?)?|watch\?v=[a-zA-Z0-9_-]+(&list=[a-zA-Z0-9_-]+(&index=\d+)?)?)$/;

  try {
    const url = new URL(string);
    if (domain === 'instagram' && !instagramRegex.test(url.href)) {
      return false;
    } else if (domain === 'youtube' && !youtubeRegex.test(url.href)) {
      return false;
    }
    return string;
  } catch (e) {
    if (domain === 'instagram') {
      return `https://www.instagram.com/${string}/`;
    } else if (domain === 'youtube') {
      const type = youTubeIdentifier(string);
      if (type === 'channelId') {
        return `https://www.youtube.com/channel/${string}`;
      } else {
        return `https://www.youtube.com/@${string}`;
    }
  }
}
};