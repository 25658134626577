import axios from 'axios';
import { API_HOST } from '../config';
import store from '../store';
import history from '../history';

let download = require('downloadjs');

const apiServer = axios.create({
  baseURL: `${API_HOST}/api/v1`,
  transformRequest: [
    function (data, headers) {
      headers['Content-Type'] = 'application/json';
      headers['Authorization'] = `Bearer ${localStorage.getItem('access')}`;
      return data;
    },
  ],
  transformResponse: [
    function (data) {
      const response = JSON.parse(data);
      if (response.code && response.code === 'token_not_valid') {
        localStorage.removeItem('access');
        history.push('/login');
      }
      return response;
    },
  ],
});


const apiServerV2 = axios.create({
  baseURL: `${API_HOST}/api/v2`,
  transformRequest: [
    function (data, headers) {
      headers['Content-Type'] = 'application/json';
      headers['Authorization'] = `Bearer ${localStorage.getItem('access')}`;
      return data;
    },
  ],
  transformResponse: [
    function (data) {
      const response = JSON.parse(data);
      if (response.code && response.code === 'token_not_valid') {
        localStorage.removeItem('access');
        history.push('/login');
      }
      return response;
    },
  ],
});

const noAuthAPIServer = axios.create({
  baseURL: `${API_HOST}/api/v1`,
  transformRequest: [
    function (data, headers) {
      headers['Content-Type'] = 'application/json';
      return data;
    },
  ],
  transformResponse: [
    function (data) {
      const response = JSON.parse(data);
      if (response.code && response.code === 'token_not_valid') {
        localStorage.removeItem('access');
        history.push('/login');
      }
      return response;
    },
  ],
});

function ValidationException(message, data) {
  const error = new Error(message);
  error.data = data;
  return error;
}

function reportingFetch(path) {
  const state = store.getState();
  const baseURL = `${API_HOST}/api/v1/campaign/${state.campaign.campaignId}/report/`;
  return fetch(baseURL + path, {
    method: 'get',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.code && response.code === 'token_not_valid') {
        localStorage.removeItem('access');
        history.push('/login');
      }
      return response;
    });
}

function adreportingFetch(path) {
  const state = store.getState();
  var campaign_id = state.campaign.campaignId;
  if (!campaign_id) {
    campaign_id = 'all';
  }
  const baseURL = `${API_HOST}/api/v1/campaign/${campaign_id}/report/`;
  return fetch(baseURL + path, {
    method: 'get',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.code && response.code === 'token_not_valid') {
        localStorage.removeItem('access');
        history.push('/login');
      }
      return response;
    });
}

function influencerReportingFetch(path, campaign_id, influencer_id) {
  const state = store.getState();
  const baseURL = `${API_HOST}/api/v1/campaign/${campaign_id}/report/${influencer_id}/`;
  return fetch(baseURL + path, {
    method: 'get',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.code && response.code === 'token_not_valid') {
        localStorage.removeItem('access');
        history.push('/login');
      }
      return response;
    });
}

export function influencerFeedFetchV2(path, page, campaign_id, influencer_id, params) {
  const state = store.getState();
  return apiServer
    .get(`campaign/${campaign_id}/social_media/influencer/feeds/${influencer_id}/` + path, {
      params: {
        page: page,
        ...params,
      },
    })
    .then((response) => {
      return response.data;
    });
}

function influencerFeedFetch(path, page, campaign_id, influencer_id, onlyStory = false) {
  var query = null;
  // var page = null
  const baseURL = `${API_HOST}/api/v1/campaign/${campaign_id}/social_media/influencer/feeds/${influencer_id}/`;
  if (page) {
    query = `?page=${page}&only_story=${onlyStory}`;
  }
  const FEED_URL = query === null ? baseURL + path : baseURL + path + `${query}`;
  return fetch(FEED_URL, {
    method: 'get',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.code && response.code === 'token_not_valid') {
        localStorage.removeItem('access');
        history.push('/login');
      }
      return response;
    });
}

function influencerBase(path, method, data, file) {
  const baseURL = `${API_HOST}/api/v1/influencer/`;
  return fetch(baseURL + path, {
    method: method,
    body: file ? file : JSON.stringify(data),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    }),
  }).then((response) => {
    if ([200, 201].includes(response.status)) return response.json();
    else if ([400].includes(response.status))
      throw new ValidationException('Form Error', response.json());
  });
}

export function influencerBaseV2(path, config) {
  const state = store.getState();
  return apiServer.get(`influencer/` + path, config).then((response) => {
    return response.data;
  });
}

function downloadFile(path, params) {
  const queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  return fetch(`${API_HOST}/api/v1${path}?${queryString}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    }),
  })
    .then(function (resp) {
      return resp.blob();
    })
    .then(function (blob) {
      download(blob, 'download.csv', 'application/csv');
    });
}

export function reportingFetchV2(path, config) {
  const state = store.getState();
  return apiServer
    .get(`campaign/${state.campaign.campaignId}/report` + path, config)
    .then((response) => {
      return response.data;
    });
}

export function influencerReportingFetchV2(path, campaign_id, influencer_id, params) {
  const state = store.getState();
  return apiServer
    .get(`campaign/${campaign_id}/report/${influencer_id}/` + path, params)
    .then((response) => {
      return response.data;
    });
}

export function feedFetch(path, page, params) {
  const state = store.getState();
  const campaignID = state.campaign.campaignId || params.campaignID
  return apiServer
    .get(`campaign/${campaignID}/social_media/feeds/` + path, {
      params: {
        page: page,
        ...params,
      },
    })
    .then((response) => {
      return response.data;
    });
}

function InfluencerList(page, data) {
  var query = null;
  // var page = null
  const baseURL = `${API_HOST}/api/v1/influencer/`;
  if (page) {
    query = `?page=${page}`;
  }
  const INFLUENCER_URL = query === null ? baseURL : baseURL + `${query}`;
  return fetch(INFLUENCER_URL, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.code && response.code === 'token_not_valid') {
        localStorage.removeItem('access');
        history.push('/login');
      }
      return response;
    });
}

function displayColumns(path, method, data) {
  const state = store.getState();
  const baseURL = `${API_HOST}/api/v1/campaign/${state.campaign.campaignId}/social_media/feeds/`;
  return fetch(baseURL + path, {
    method: method,
    body: JSON.stringify(data),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    }),
  }).then((response) => {
    if ([200, 201].includes(response.status)) return response.json();
    else if ([400].includes(response.status))
      throw new ValidationException('Form Error', response.json());
  });
}

function cityStateDropdown(path, data) {
  const baseURL = `${API_HOST}/api/v1/influencer/`;
  return fetch(baseURL + path, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    }),
  }).then((response) => {
    if ([200, 201, 400].includes(response.status)) return response.json();
  });
}

function esFetch(path) {
  const state = store.getState();
  const baseURL = `${API_HOST}/api/v1/es/${state.campaign.campaignId}/`;
  return fetch(baseURL + path, {
    method: 'get',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.code && response.code === 'token_not_valid') {
        localStorage.removeItem('access');
        history.push('/login');
      }
      return response;
    });
}

const noAuthAPIServer2 = axios.create({
  baseURL: `${API_HOST}/api/v1`,
  transformRequest: [
    function (data, headers) {
      headers['Content-Type'] = 'application/json';
      return data;
    },
  ],
  transformResponse: [
    function (data) {
      const response = JSON.parse(data);
      // if (response.code && response.code === 'token_not_valid') {
      //   localStorage.removeItem('access');
      //   history.push('/login');
      // }
      return response;
    },
  ],
});

const noAuthAPIServerV2 = axios.create({
  baseURL: `${API_HOST}/api/v2`,
  transformRequest: [
    function (data, headers) {
      headers['Content-Type'] = 'application/json';
      return data;
    },
  ],
  transformResponse: [
    function (data) {
      const response = JSON.parse(data);
      // if (response.code && response.code === 'token_not_valid') {
      //   localStorage.removeItem('access');
      //   history.push('/login');
      // }
      return response;
    },
  ],
});

const apiServerFormDataV2 = axios.create({
  baseURL: `${API_HOST}/api/v2`,
  transformRequest: [
    function (data, headers) {
      headers['Content-Type'] = 'multipart/form-data';
      headers['Authorization'] = `Bearer ${localStorage.getItem('access')}`;
      return data;
    },
  ],
  transformResponse: [
    function (data) {
      const response = JSON.parse(data);
      if (response.code && response.code === 'token_not_valid') {
        localStorage.removeItem('access');
        history.push('/login');
      }
      return response;
    },
  ],
});

export {
  apiServer,
  apiServerV2,
  apiServerFormDataV2,
  reportingFetch,
  influencerReportingFetch,
  influencerBase,
  ValidationException,
  downloadFile,
  InfluencerList,
  adreportingFetch,
  displayColumns,
  cityStateDropdown,
  noAuthAPIServer,
  noAuthAPIServer2,
  noAuthAPIServerV2,
  esFetch,
};
